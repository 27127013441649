import { Injectable } from '@angular/core';
import { DataService, storeUrl, storesUrl,fetchStores }  from '../../shared/data.service';
import { Store } from '../shared/store.model';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  selectedMenu: Store;
  isDetailComponentShowing = false;
  isSettingsComponentShowing = false;

  //private previousDetail: Store;

  constructor(private dataService: DataService) { 
    
  }

  getStore(value:string) {
    var url = storeUrl+'?store_no='
    return this.dataService.get(url,value);
  }

  getStores() {
    return this.dataService.get(storesUrl,null);
  }

  getStoreWithId(_id: string) {
    return this.dataService.get(storeUrl, _id);
  }

  showSuccessPostMessage() {
    this.dataService.showSuccessPostMessage();
  }

  showSuccessDeleteMessage() {
    this.dataService.showSuccessDeleteMessage();
  }

  showSuccessPatchMessage() {
    return this.dataService.showSuccessPatchMessage();
  }

  showErrorFillMessage() {
    this.dataService.showErrorFillMessage();
  }

  log(message: any) {
    return this.dataService.log(message);
  }

  getStoreList(msearch: string, offset: string, limit: string){
    return this.dataService.get(fetchStores + '?mseach=' + msearch + '&offset=' + offset + '&limit=' + limit, null);
  }

}
