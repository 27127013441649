import { Observable } from 'rxjs/Observable';
import { DataService, shipRecord } from './../../shared/data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../shared/dashboard.service';
import { MatTableDataSource, MatSort } from '@angular/material';
import { Papa } from 'ngx-papaparse';
import { MatDialog } from '@angular/material';
import { MaintenanceOptionComponent } from '../shared/maintenance-option/maintenance-option.component';
import { BadgeSocketService } from 'src/app/shared/badge-socket.service';


@Component({
  selector: 'app-db-vr',
  templateUrl: './db-vr.component.html',
  styleUrls: ['./db-vr.component.scss'],
  //providers: [Papa, BadgeSocketService]
})

export class DbVrComponent implements OnInit {

  task_records: {};

  constructor(
    public badgeService: BadgeSocketService,
    public dialog : MatDialog,
    private service: DashboardService,
  ) { 
    console.log('socket response')
    //this.badgeService.listenBadgeUpdate('vr').subscribe( res => {
    //  console.log('socket response', res)
    //})
  }

  ngOnInit() {
    this.getTask();
    let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
    //this.badgeService.setOnline({user: tmp.userid})
  }

  //ngOnDestroy(): void {
  //  let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
  //  this.badgeService.setOffline({user: tmp.userid})
  //}

  getTask (){
    this.service.getTaskRecords('vr_main').subscribe(res => {
      this.task_records = res.payload.task_records;
    })
  }
  maintenanceOpt(t) { 
    const dialog = this.dialog.open(MaintenanceOptionComponent, {
      width: '980px',
      data: {
        'userType': 'vr',
        'mData': t
      }

    })
    dialog.afterClosed().subscribe(res => {
      this.getTask();
    })
  }

}
