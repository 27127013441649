import { Injectable } from '@angular/core';

@Injectable()

export class InquiryData {
    public store: any;
    public inquiry: any;
    public ref_code: string;
    public constructor() { }
}
