import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../shared/dashboard.service';
import { MatDialog } from '@angular/material';
import { InqConfirmComponent } from '../shared/inq-confirm/inq-confirm.component';
import { OrderConfirmComponent } from '../shared/order-confirm/order-confirm.component';
import { Inquiries,allOrders,OrderRecords } from '../shared/ordered-item.model';
import { ManualList } from '../../manual-management/shared/manual-list-interface';
import { ManualService } from '../../manual-management/shared/manual.service';​
import { BadgeSocketService } from 'src/app/shared/badge-socket.service';

@Component({
  selector: 'app-db-rr',
  templateUrl: './db-rr.component.html',
  styleUrls: ['./db-rr.component.scss'],
  //providers:[BadgeSocketService]
})

export class DbRrComponent implements OnInit {

  inquiries:  Inquiries[]    = [];
  orders   :  OrderRecords[] = [];
  allOrders:  allOrders[]    = [];
  manuals  :  ManualList[]   = [];

  task_records: any;
  isActive: '';
  tab: string

  itemStr: string[] = []

  iCnt: number;
  hasInqData = false;
  hasOrderData = false;
  badges: any[] = []

  constructor(
    public badgeService: BadgeSocketService,
    private service:DashboardService,
    private manualService: ManualService,
    public dialog: MatDialog
  ) { 
    console.log('socket response')
    //this.badgeService.listenBadgeUpdate('rr').subscribe( res => {
    //  console.log('socket response', res)
    //})
  }

  ngOnInit() {
    //this.getInquiries();
    //this.getOrders();
    // this.getTasks()
    this.getManuals();
    this.getBadgeCnt()
    let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
    //this.badgeService.setOnline({user: tmp.userid})
  }

  //ngOnDestroy(): void {
  //  let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
  //  this.badgeService.setOffline({user: tmp.userid})
  //}

  getBadgeCnt(){
    this.service.getDashboardCnt('rr').subscribe(res => {
        this.tchange('inq');
        this.badges = res.result
    })
  }

  changeTab(event) {
    this.tchange(event.tab.textLabel);
  }

  tchange(info) {
    this.tab = info
    this.service.getTaskRecords('rr_' + info).subscribe(res => {
        console.log('getTaskRecords', res)
        if (res.success === true) {
            this.isActive = info;
            this.task_records = res.payload.task_records;
            if (info === 'ord') {
                for (let tmp of res.payload.task_records) {
                    let str: string = ''
                    for (let y = 0; y < tmp.ordered_items.length; y++) {
                        str += tmp.ordered_items[y].name + '×' + tmp.ordered_items[y].qty + (y < (tmp.ordered_items.length - 1) ? ', ' : '')
                    }
                    this.itemStr.push(str)
                }
            }

          

        } else {
            this.hasInqData = true;
        }

    })
  }

  getManuals(){
    this.manualService.getManualList().subscribe(res => { 
    if(res.success){
        this.manuals = res.payload.files  
        console.log('Manual',this.manuals);
        this.manuals = [];
        }
        
    })
  }

  getTasks(){
    // this.inquiries = []
    // this.service.getTaskList('1').subscribe(res => {
    // console.log('tasks', res)
    // this.inquiries = res.payload.task_records
    // this.hasInqData = true;
    // })
  }

  getInquiries() {
    this.service.getInquiries().subscribe(res => {
      if(res.success === true) {
        var data = res.payload.inquiries;
        var filterdInq = data.filter(function(item, index){
          if (item.esc_direction === 2 && item.esc_status < 4) return true;
        });
        this.inquiries = filterdInq;
        this.hasInqData = true;
        console.warn(this.inquiries);
      } else {
        console.log("CAN NOT OBTAINED");
      }
    });
  }

  getOrders() {
    this.service.getOrderRecords().subscribe(res=>{
      var data = res.payload.order;
      console.warn(data);
      var filterOrder = data.filter(x => x.esc_status === 1 || x.esc_status === 3 || x.esc_status === 5 || x.esc_status === 7);
      this.orders = filterOrder;
      console.warn(this.orders);
      this.hasOrderData = true;
    })
  }

  onOrderConfirm(rowData: OrderRecords,o:any) {
    const dialogRef = this.dialog.open(OrderConfirmComponent,
        {
            width: '980px',
            data: {
                'type': 'rr',
                'value': {
                  'order_record': rowData,
                  'update_order': o
                }
              }
        }
        
    )
    dialogRef.afterClosed().subscribe(res => {
        if (res) this.tchange(this.tab);
    }) 
  }

  // onOrderConfirm(rowData:OrderRecords){
  //   const dialogRef = this.dialog.open(OrderConfirmComponent,
  //     { 
  //       width: '650px',
  //       data: {
  //         'type': 'hd',
  //         'value':rowData,
  //       }
  //     }
  //   )
  //   dialogRef.afterClosed().subscribe(res => {
  //     if(res) this.getOrders()
  //   })
  // }

  //Confirm Modal
  onInqConfirm(rowData: Inquiries) {
    const dialogRef = this.dialog.open(InqConfirmComponent,
        {
            width: '980px',
            data: {
                'type': 'rr',
                'mdata': rowData
            }
        }
    )
    dialogRef.afterClosed().subscribe(res => {
        if (res) this.tchange(this.tab);
    })
  }
}
