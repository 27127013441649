import { InquiryService } from './../../inquiry/shared/inquiry.service';
import { Component, OnInit,ViewChild,HostListener, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute,Params } from '@angular/router';
import { Store } from '../../store/shared/store.model';
import { StoreService } from '../../store/shared/store.service';
import { Collection } from '../shared/collection.model';
import { CollectionService } from '../shared/collection.service';
import { CollectionData } from '../shared/collection.prov';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

import * as _moment from 'moment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { formatDate } from '@angular/common';
//import {default as _rollupMoment} from 'moment';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface KeyValue {
  key: string;
  value: string;
}

@Component({
  selector: 'app-collection-input-parent',
  templateUrl: './collection-input.component.html',
  styleUrls: ['./collection-input.component.scss'],
  providers: [ 
    StoreService, CollectionService, InquiryService,
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})

export class CollectionInputComponent implements OnInit {

  txtArea = '1. 依頼日\n'+
            '2. 引き取り希望日\n'+
            '3. 引き取り希望時間\n'+
            '4. 郵便番号\n'+
            '5. 住所\n'+
            '6. 電話番号\n'+
            '7. 店舗名称\n'+
            '8. 回収品名称\n'+
            '9. 回収品サイズ\n'+
            '10. 回収品重量\n'+
            '11. 回収品個数\n'+
            '12. ご担当者様名\n'+
            '13. ご担当者様連絡先'

  date = new FormControl(moment());
  mform: FormGroup

  time: KeyValue[] = [
    { key: "am", value: "AM" },
    { key: "pm", value: "PM" },
    { key: "nn", value: "NN" }
  ];

  public id: string;
  store: Store;
  inquiry: Collection;
  isLoading = false;
  hasData: boolean = false;
  message = '';
  pMsg = '';
  uFlag = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private storeService: StoreService,
    private collectionService: CollectionService,
    private collectionData: CollectionData,
    public http: HttpClient,
    private inquiryService: InquiryService,
    private formBuilder: FormBuilder
  ){}

  ngOnInit() {
    // console.log('ngOnInit collectionData input', this.collectionData)
    let index = 0
    if(this.collectionData.shop_date_hopes){
      this.date.setValue(new Date(formatDate(this.collectionData.shop_date_hopes, 'longDate', 'en-US')))
      
    }
    this.collectionData.remarks = this.txtArea
    this.mform = this.formBuilder.group({
      remarks: [this.collectionData.remarks, Validators.required],
      address: [this.collectionData.address, Validators.required],
      shop_incharge: [this.collectionData.shop_incharge, Validators.required],
      phone_number: [this.collectionData.phone_number, Validators.required],
      shop_date_hopes : [this.collectionData.shop_date_hopes],
      urg_flg: [this.collectionData.urg_flg]
    })
    

    this.id = this.route.snapshot.paramMap.get('id');
    this.getStore();
    this.uFlag = this.collectionData.urg_flg;
  }
  
  getStore() {
    this.storeService.getStore(this.id).subscribe(res => {
      this.store = res.payload.store[0];
      //this.getChainName()
      // console.log('store', this.store)
    });
  }

  onFlagChange(){
    this.uFlag = !this.uFlag;
    console.log(this.uFlag)
  }

  maxCount = 1000;
  restCount = this.maxCount;
  alertStyle = {};
  
  inputText() {
    this.restCount = this.maxCount - this.message.length;

    if ( this.restCount > 30 ) {
      this.alertStyle = {color: '#000', fontWeight: 'normal'};
    } else if ( this.restCount > 0 ) {
      this.alertStyle = {color: '#f33', fontWeight: 'normal'};
    } else {
      this.alertStyle = {color: '#f00', fontWeight: 'bold'};      
    }
  }

  onSubmit(store:any) {
    let tmp = this.mform.getRawValue()
    let date = tmp.shop_date_hopes
                ? formatDate(new Date(tmp.shop_date_hopes), 'yyyy-MM-dd', 'en-US')
                : null

    if( !tmp.remarks   
      || !tmp.address
      || !tmp.shop_incharge
      || !tmp.phone_number
      ){
      console.log('required');
      return;
    }
    console.log( "this is the store from input  ", this.store)  
    this.collectionData.store = this.store;
   
    
    this.collectionData.remarks = tmp.remarks
    this.collectionData.address = tmp.address
    this.collectionData.shop_incharge = tmp.shop_incharge
    this.collectionData.phone_number = tmp.phone_number
    this.collectionData.shop_date_hopes = date
    this.collectionData.urg_flg = this.uFlag
   
    this.router.navigate(['/main/collection/confirm/' + store.store_no ]);
  }
}