import { Component, OnInit,ViewChild,HostListener, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute,Params } from '@angular/router';
import { Store } from '../../store/shared/store.model';
import { StoreService } from '../../store/shared/store.service';
import { Inquiry } from '../shared/inquiry.model';
import { InquiryService } from '../shared/inquiry.service';
import { InquiryData } from '../shared/inquiry.prov';

@Component({
  selector: 'app-inquiry-input-parent',
  templateUrl: './inquiry-input.component.html',
  styleUrls: ['./inquiry-input.component.scss'],
  providers: [ StoreService, InquiryService ]
})

export class InquiryInputComponent implements OnInit {

  public id: string;
  store: Store;
  inquiry: Inquiry;
  isLoading = false;
  hasData: boolean = false;
  pMsg = '';
  message = '';
  uFlag = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private storeService: StoreService,
    private inquiryService: InquiryService,
    private inquiryData: InquiryData,
    public http: HttpClient,
  ){}
 
  ngOnInit() {
    this.inquiryData.ref_code == "";
    this.id = this.route.snapshot.paramMap.get('id');
    this.getStore();

    //if(this.inquiryData.inquiry){
    //    console.warn(this.inquiryData.inquiry);
    //    this.message = this.inquiryData.inquiry.detail;
    //}
    if(!this.inquiryData.ref_code && this.inquiryData.inquiry){
      this.message = this.inquiryData.inquiry.detail;
    }
    else {
      this.message == "";
    }
    
    
    this.uFlag = false;
  }
  
  getStore() {
    this.storeService.getStore(this.id).subscribe(res => {
      this.store = res.payload.store[0];
      console.log('getStore', res)
      this.getChainName()
    });
  }

  chainName: string
  getChainName(){
    this.inquiryService.getChainName(this.store.chain_no)
    .subscribe(res => {
      console.log('getChainName', res)
      this.chainName = res.payload.chain[0].name
    })
  }

  onFlagChange(){
    if(this.uFlag === true){
      this.uFlag = true;
    } else {
      this.uFlag = false;
    }
  }

  maxCount = 1000;
  restCount = this.maxCount;
  alertStyle = {};
  
  inputText() {
    this.restCount = this.maxCount - this.message.length;

    if ( this.restCount > 30 ) {
      this.alertStyle = {color: '#000', fontWeight: 'normal'};
    } else if ( this.restCount > 0 ) {
      this.alertStyle = {color: '#f33', fontWeight: 'normal'};
    } else {
      this.alertStyle = {color: '#f00', fontWeight: 'bold'};      
    }
  }

  esc = [
    { key: 7, value: "なし"},
    { key: 0, value: "コールセンター" },
    { key: 2, value: "博報堂" },
    { key: 4, value: "倉庫" },
    { key: 1, value: "ラウンダー" }
    
  ];

  selectedEsc: any = 7;

  onSubmit(store:any) {
    if (this.selectedEsc == ""){
      this.selectedEsc == 7;
    }
    else if (this.selectedEsc == 4){
      this.selectedEsc == 2;
    }
    this.inquiryData.store = store;
    this.inquiryData.store.chain_name = this.chainName;
    this.inquiryData.inquiry = { 'detail' : this.message, 'uFlag' : this.uFlag, 'esc_direction' : this.selectedEsc }
    this.router.navigate(['/main/inquiry/confirm/' + store.store_no ]);
  }
}