import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-uploaded-dialog',
  templateUrl: './uploaded-dialog.component.html',
  styleUrls: ['./uploaded-dialog.component.scss']
})
export class UploadedDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<UploadedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}
