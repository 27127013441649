import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-redirect-dashboard',
  templateUrl: './redirect-dashboard.component.html',
  styleUrls: ['./redirect-dashboard.component.scss']
})
export class RedirectDashboardComponent implements OnInit {

  str: string

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    let userInf: any = JSON.parse(sessionStorage.getItem('currentUser'))
    if(userInf.usertype === 0) this.router.navigate(['../CC'], {relativeTo: this.route})
    else if(userInf.usertype === 1) this.router.navigate(['../RR'], {relativeTo: this.route})
    else if(userInf.usertype === 2) this.router.navigate(['../HD'], {relativeTo: this.route})
    else if(userInf.usertype === 3) this.router.navigate(['../GG'], {relativeTo: this.route})
    else if(userInf.usertype === 4) this.router.navigate(['../WH'], {relativeTo: this.route})
    else if(userInf.usertype === 5) this.router.navigate(['../VR'], {relativeTo: this.route})
    else if(userInf.usertype === 7) this.router.navigate(['../DA'], {relativeTo: this.route})
    console.log(userInf.usertype)
  }

}
