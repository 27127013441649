import { Component, OnInit } from '@angular/core';
//import { CollectionService } from './shared/collection.service';

@Component({
  selector: 'app-waiting_task',
  templateUrl: './waiting_task.component.html',
  styleUrls: ['./waiting_task.component.scss'],
  //providers: [ CollectionService ]
})
export class WaitingTaskComponent implements OnInit {

  //constructor(public service: CollectionService) { }

  ngOnInit() {
  }
}
