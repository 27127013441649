import { Injectable } from '@angular/core';
import { DataService, collectionUrl, saveRelation }  from '../../shared/data.service';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent, HttpEventType ,HttpParams, HttpResponse} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class CollectionService {
  enableLog: boolean;
  constructor(private dataService: DataService, public http: HttpClient
    ) { this.enableLog = true }
    public log(message: any) { if (this.enableLog) console.log(message); }
  
    tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))


  setCollection(body:any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params: any
    params = new HttpParams()
      .set('store_id', body.store_id)
      .set('address', body.address)
      .set('shop_incharge', body.shop_incharge)
      .set('shop_date_hopes', body.shop_date_hopes)
      .set('provider_name',body.provider_name)
      .set('driver_name',body.driver_name)
      .set('phone_number',body.phone_number)
      .set('driver_contact_number',body.driver_contact_number)
      .set('coll_date',body.coll_date)
      .set('coll_time',body.coll_time)
      .set('status',body.status)
      .set('remarks',body.remarks)
      .set('urg_flg',body.urg_flg)
      .set('user_id',body.user_id)

    return this.http.post(collectionUrl, params.toString(), { headers,responseType: 'json' })
      .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
      .catch((err: any) => Observable.of(false));
  }

  updateCollection(body: any){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params: any
    params = new HttpParams()
      .set('provider_name', body.provider_name)
      .set('driver_name', body.driver_name)
      .set('driver_contact_number', body.driver_contact_number)
      .set('coll_date', body.coll_date)
      .set('coll_time',body.coll_time)
      .set('user_id',body.user_id)
      .set('coll_ref_no',body.coll_ref_no)
      .set('cc_comment',body.cc_comment)
      return this.http.put(collectionUrl + '/' + body.id, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    
  }

  saveRelation(body: any){
    return this.dataService.postRelation(saveRelation, body)
  }

  updateRelation(body: any){
    return this.dataService.putRelation(saveRelation, body)
  }

}