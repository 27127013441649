import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Chain_list } from '../../chain-management/shared/chain.model';

@Component({
  selector: 'app-inbound-view',
  templateUrl: './inbound-view.component.html',
  styleUrls: ['./inbound-view.component.scss']
})
export class InboundViewComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<InboundViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Chain_list) { }

  ngOnInit() {
  }

  getUrg(stat: number): string{
    if(stat === 0) return '通常（至急ではない）'
    return '至急'
  }

  getDir(dir: string): string{
    if(dir == '0') return 'コールセンター'
    else if(dir == '2') return '博報堂'
    else if(dir == '1') return 'ラウンダー'
    else if(dir == '4') return '倉庫'
    else if(dir == '7') return 'なし'
  }

}
