import { CarrierOptComponent } from './../carrier-opt/carrier-opt.component';
import { Carrier_list } from './../shared/carrier.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { CarrierService } from '../shared/carrier.service';
import { CarrierViewComponent } from '../carrier-view/carrier-view.component';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { Chain_list } from '../../chain-management/shared/chain.model';
import { ChainService } from '../../chain-management/shared/chain.service';
import { CarrierFirstCatlist } from '../../carrier-first-cat-management/shared/carrier-first-cat.model';
import { CarrierSecondCatlist } from '../../carrier-second-cat-management/shared/carrier-second-cat.model';
import { CarrierThirdCatlist } from '../../carrier-third-cat-management/shared/carrier-third-cat.model';

@Component({
  selector: 'app-carrier-list',
  templateUrl: './carrier-list.component.html',
  styleUrls: ['./carrier-list.component.scss']
})
export class CarrierListComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  stores: Carrier_list[] = [];
  dataSource: MatTableDataSource<Carrier_list>;
  displayedColumns = ['id', 'store_name', 'chain_no', 'address', 'viewAction', 'editAction', 'deleteAction'];
  pbVisible: boolean = false;
  msearch: string = ''
  offset: number = 0
  limit: string = '20'
  total: number = 0
  search_loading: boolean = false
  carrier_first_cat: CarrierFirstCatlist[] = [];
  carrier_second_cat: CarrierSecondCatlist[] = [];
  carrier_third_cat: CarrierThirdCatlist[] = [];

  constructor(
    public service: CarrierService
    , public dialog: MatDialog
    , public chainService: ChainService
  ) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.stores);
    this.getStores(this.msearch);
    this.dataSource.sort = this.sort;
    this.getCarrierFirstCat()
    this.getCarrierSecondCat()
    this.getCarrierThirdCat()
  }

  getCarrierFirstCat() {
    this.service.getCarrierFirstCat().subscribe(res => {
      this.carrier_first_cat = res.payload.carrier_first_cat;
    });
  }

  getCarrierSecondCat() {
    this.service.getCarrierSecondCat().subscribe(res => {
      this.carrier_second_cat = res.payload.carrier_second_cat;
    });
  }

  getCarrierThirdCat() {
    this.service.getCarrierThirdCat().subscribe(res => {
      this.carrier_third_cat = res.payload.carrier_third_cat;
    });
  }

  getStores(tosearch: string){
    this.search_loading = true
    this.msearch = tosearch.trimLeft()
    this.msearch = this.msearch.trimRight()
    this.service.getStoreList(this.msearch, '' + this.offset, this.limit)
    .subscribe(res => {
      this.pbVisible = true
      this.search_loading = false
      if(res.success){
        this.stores = res.payload.stores
          this.dataSource.data = this.stores
          this.dataSource.filterPredicate = (data: Carrier_list, filters: string) =>{
            const matchFilter = [];
            const filterArray = filters.split(' ')
            const columns = [ data.store_name, data.store_name_kana, data.address, data.tel.toString() ]
            filterArray.forEach(filter =>{
              const customFilter = [];
              columns.forEach(column => customFilter.push(column.toLowerCase().includes(filter)));
              matchFilter.push(customFilter.some(Boolean));
            })
            return matchFilter.every(Boolean);
          }
      }
    })
  }

  limitChange(){
    // let o = offset, l = limit, t = total, N = new offset
    // N = o - ( ( o + l ) - t )
    this.offset = Math.max(0, this.offset - Math.max(0, (this.offset + (+this.limit)) - this.total))
    this.getStores(this.msearch)
  }

  actionPage(action: number){
    if(action == 1){ // Fist Page
      this.offset = 0
      this.getStores(this.msearch)
    }else if(action == 2){ // Previous Page
      let tmp = this.offset - (+this.limit)
      if(this.offset == 0) return
      this.offset = tmp <= 0 ? 0 : tmp
      this.getStores(this.msearch)
    }else if(action == 3){ // Next Page
      let tmp = this.offset + (+this.limit)
      if(tmp >= this.total) return
      this.offset = tmp
      this.getStores(this.msearch)
    }else if(action == 4){ // Last Page
      let ttl = this.total - (+this.limit)
      this.offset = ttl <= 0 ? 0 : ttl
      this.getStores(this.msearch)
    }
  }

  tmpItem: Carrier_list[];
  filterValue_FirstCat : any;
  filterValue_SecondCat : any;
  filterValue_ThirdCat : any;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterByFirstCat(filterValue_FirstCat: any) {
    this.filterValue_FirstCat = filterValue_FirstCat;
     this.applyFilterCategories()
   }

  applyFilterBySecondCat(filterValue_SecondCat: any) {
    this.filterValue_SecondCat = filterValue_SecondCat;
    this.applyFilterCategories()
  }

  applyFilterByThirdCat(filterValue_ThirdCat: any) {
    this.filterValue_ThirdCat = filterValue_ThirdCat;
     this.applyFilterCategories()
   }

   applyFilterCategories() {
    this.tmpItem = [];
    // 1st Cat ALL;
    if (this.filterValue_FirstCat === 'ALL' ) {
      //2nd Cat  ALL
      if (this.filterValue_SecondCat === 'ALL' ) {

          if (this.filterValue_ThirdCat === 'ALL' ) {
            this.tmpItem = this.stores
          }
          else if (this.filterValue_ThirdCat != 'ALL'){
            for (let x = 0; x < this.stores.length; x++) {
              if (this.stores[x].third_cat === this.filterValue_ThirdCat) {
                this.tmpItem.push(this.stores[x])
                console.log()
              }
            }
          }
      }
      //2nd Cat ANY
      else if (this.filterValue_SecondCat != 'ALL'){
        
        if (this.filterValue_ThirdCat === 'ALL' ) {
          for (let x = 0; x < this.stores.length; x++) {
            if (this.stores[x].second_cat === this.filterValue_SecondCat) {
              this.tmpItem.push(this.stores[x])
            }
          }
        }
        else if(this.filterValue_ThirdCat != 'ALL'){
          for (let x = 0; x < this.stores.length; x++) {
            if (this.stores[x].second_cat === this.filterValue_SecondCat && this.stores[x].third_cat === this.filterValue_ThirdCat) {
              this.tmpItem.push(this.stores[x])
            }
          }
        }
      }
    }
    // 1st Cat ANY;
    else if (this.filterValue_FirstCat != 'ALL') {
      //2nd Cat  ALL
      if (this.filterValue_SecondCat === 'ALL' ) {

        if (this.filterValue_ThirdCat === 'ALL' ) {
          for (let x = 0; x < this.stores.length; x++) {
            if (this.stores[x].first_cat === this.filterValue_FirstCat) {
              this.tmpItem.push(this.stores[x])
            }
          }
        }
        else if (this.filterValue_ThirdCat != 'ALL'){
          for (let x = 0; x < this.stores.length; x++) {
            if (this.stores[x].first_cat === this.filterValue_FirstCat && this.stores[x].third_cat === this.filterValue_ThirdCat) {
              this.tmpItem.push(this.stores[x])
            }
          }
        }
      }
      //2nd Cat ANY
      else if (this.filterValue_SecondCat != 'ALL'){
        
        if (this.filterValue_ThirdCat === 'ALL' ) {
          for (let x = 0; x < this.stores.length; x++) {
            if (this.stores[x].first_cat === this.filterValue_FirstCat && this.stores[x].second_cat === this.filterValue_SecondCat) {
              this.tmpItem.push(this.stores[x])
            }
          }
        }
        else if(this.filterValue_ThirdCat != 'ALL'){
          for (let x = 0; x < this.stores.length; x++) {
            if (this.stores[x].first_cat === this.filterValue_FirstCat && this.stores[x].second_cat === this.filterValue_SecondCat && this.stores[x].third_cat === this.filterValue_ThirdCat) {
              this.tmpItem.push(this.stores[x])
            }
          }
        }
      }
    }
    this.dataSource.data = this.tmpItem
  }

  openDiaog(rowData: Carrier_list){
    this.dialog.open(CarrierViewComponent, { width: '980px', height:'730px',
    data: rowData })
  }

  openOptionDialog(opt: string, val: Carrier_list){
    const dialogRef = this.dialog.open(CarrierOptComponent, { width: '980px', height:'730px',
      data: {
          option: opt, desc: val ,
          carrier_first_cat : this.carrier_first_cat,
          carrier_second_cat : this.carrier_second_cat,
          carrier_third_cat : this.carrier_third_cat
        } ,
      disableClose: true
    })
    dialogRef.afterClosed().subscribe(res => {
      if(res) this.getStores(this.msearch)
    })
  }
  openOptionDialogAdd(opt: string){
    let userTmp: Carrier_list[] = []
    const dialogRef = this.dialog.open(CarrierOptComponent, { width: '980px', height:'730px',
      data: {
        option: opt, desc: userTmp ,
        carrier_first_cat : this.carrier_first_cat,
        carrier_second_cat : this.carrier_second_cat,
        carrier_third_cat : this.carrier_third_cat

      } ,
    disableClose: true
    })
    dialogRef.afterClosed().subscribe(res => {
      if(res) this.getStores(this.msearch)
    })
  }

  onDelete(val: Carrier_list){
    
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {
        name: val.store_name
      }
    })
    dialogRef.afterClosed().subscribe(res => {
      if(res){
        let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
        let body = {
          'id': val.id,
          //'store_no': val.store_no,
          'deleted_by': tmp.userid
        }
        this.service.carrierOpt(body, 'delete')
        .subscribe(res => {
          this.getStores(this.msearch)
        })
      }
    })
  }

}
