import { Injectable } from '@angular/core';
import * as io from 'socket.io-client'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BadgeSocketService {

  constructor() { }

  //private socket = io('http://localhost:8090')
  // private socket = io('https://gcrms-dev-api.appspot.com:65080/')
  // private socket = io('https://g-retail-stg-api.appspot.com:65080/')
  private socket = io('https://g-retail-prod-api.appspot.com:65080/')  

  /*setOnline(data){
    this.socket.emit('join', data)
  }
  
  getOnlines(){
    let observable = new Observable<{user: String, message: string}>(observer => {
      this.socket.on('online', (data)=>{
        observer.next(data)
      })
      return () => {this.socket.disconnect()}
    })
    return observable
  }

  setOffline(data){
    this.socket.emit('leave', data)
  }
  
  getOfflines(){
    let observable = new Observable<{user: String, message: string}>(observer => {
      this.socket.on('offline', (data)=>{
        observer.next(data)
      })
      return () => {this.socket.disconnect()}
    })
    return observable
  }

  setBadgeUpdate(data){
    this.socket.emit('badge', data)
  }
  
  listenBadgeUpdate(db: string){
    let observable = new Observable<{user: String, message: string}>(observer => {
      this.socket.on(db, (data)=>{
        observer.next(data)
      })
      return () => {this.socket.disconnect()}
    })
    return observable
  }

  pushBadgeNotif(notif){
    let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
    if(notif.cc) this.setBadgeUpdate({user: tmp.userid, dashboard: 'cc'})
    if(notif.gg) this.setBadgeUpdate({user: tmp.userid, dashboard: 'gg'})
    if(notif.hd) this.setBadgeUpdate({user: tmp.userid, dashboard: 'hd'})
    if(notif.rr) this.setBadgeUpdate({user: tmp.userid, dashboard: 'rr'})
    if(notif.vr) this.setBadgeUpdate({user: tmp.userid, dashboard: 'vr'})
    if(notif.wh) this.setBadgeUpdate({user: tmp.userid, dashboard: 'wh'})
  }
  */
}
