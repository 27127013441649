import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-db-da',
  templateUrl: './db-da.component.html', 
  styleUrls: ['./db-da.component.scss']
})
export class DbDaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
