import { Injectable } from '@angular/core';
import { DataService, carrierOptUrl, delCarrierUrl, fetchCarriers } from '../../shared/data.service';
import { CarrierFirstCatUrl , CarrierSecondCatUrl, CarrierThirdCatUrl } from '../../shared/data.service';
import { HttpClient} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class CarrierService {

  constructor(private dataService: DataService, public http: HttpClient) { }

  getStoreList(msearch: string, offset: string, limit: string){
    return this.dataService.get(fetchCarriers + '?mseach=' + msearch + '&offset=' + offset + '&limit=' + limit, null);
  }

  carrierOpt(body: any, opt: string){
    if(opt === 'delete')
      return this.dataService.carrierOpt( delCarrierUrl, body, opt);
    else
      return this.dataService.carrierOpt( carrierOptUrl, body, opt); 
  }

  getCarrierFirstCat(){
    return this.dataService.get(CarrierFirstCatUrl, null);
  }
  getCarrierSecondCat(){
    return this.dataService.get(CarrierSecondCatUrl, null);
  }
  getCarrierThirdCat(){
    return this.dataService.get(CarrierThirdCatUrl, null);
  }
}
