import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, Sort , MatPaginator } from '@angular/material';
import { MatDialog } from '@angular/material';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { ManualList } from './shared/manual-list-interface';
import { ManualService } from './shared/manual.service';

@Component({
  selector: 'app-manual-management',
  templateUrl: './manual-management.component.html',
  styleUrls: ['./manual-management.component.scss'],
})
export class ManualManagementComponent implements OnInit {
 
  @ViewChild(MatSort) sort: MatSort; 

  manual: ManualList[];
  displayedColumns: string[] = ['id' ,'original_filename', 'file_description', 'downloadAction']; 
  dataSource: MatTableDataSource<ManualList>;
  
  pbVisible: boolean = false;

  constructor( public dialog: MatDialog, private service: ManualService) { }
 
  ngOnInit() {  
    this.dataSource = new MatTableDataSource(this.manual);
    this.dataSource.sort = this.sort; 
    this.getManuals();  
  } 

  getManuals(){
    this.service.getManualList().subscribe(res => {
      this.pbVisible = true
      
      if(res.success){
        this.manual = res.payload.files 
        if (this.manual.length) {
          this.dataSource.data = this.manual;
          this.dataSource.filterPredicate = (data: ManualList, filters: string) =>{
            const matchFilter = [];
            const filterArray = filters.split(' ')

            const columns = [data.original_filename, data.file_description]

            filterArray.forEach(filter =>{
              const customFilter = [];
              columns.forEach(column => customFilter.push(column.toLowerCase().includes(filter)));
              matchFilter.push(customFilter.some(Boolean));
            })
            return matchFilter.every(Boolean);
          }
        }
      }
    })
  }

  applyFilter(filterValue: string) { 

    this.dataSource.filter = filterValue.trim().toLowerCase();
    
  }

  addFile() { 
    const DialogOpen = this.dialog.open(UploadFileComponent, { width: '980px' } )
    DialogOpen.afterClosed().subscribe(res => {
      if(res) {
        this.getManuals()
      };  
    })
  }

}

