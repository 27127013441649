import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { Items } from '../shared/item.model';
import { ItemService } from '../shared/item.service';
import { ItemViewComponent } from '../item-view/item-view.component';
import { ItemOptComponent } from '../item-opt/item-opt.component';
import { ConfirmDialogComponent } from './../../shared/confirm-dialog/confirm-dialog.component';
import { ReviewStockComponent } from '../review-stock/review-stock.component';
import { Item_categ_list } from '../../item-categ-management/shared/item-categ.model';
import { Item_categ_first_list } from '../../item-categ-first-management/shared/item-categ-first.model';

import * as _ from 'lodash';
import { imagesUrl } from 'src/app/shared/data.service';

export interface KeyValue {
  key: any;
  value: string;
}

export interface stock {
  item_id: any;
  booked_stock: any;
  name : any;
  old_booked_stock: any;
}

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss']
})
export class ItemListComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  items: Items[] = [];
  multipleStocks: stock[] = [];
  msearch: string = ''
  offset: number = 0
  limit: string = '9999999999'
  total: number = 0
  category: Item_categ_list[] = [];
  first_category: Item_categ_first_list[] = [];
  img_value = "NONE"
  
  dataSource: MatTableDataSource<Items>;
  displayedColumns = [
    'item_no'
    ,'img'
    , 'name'
    , 'booked_stock'
    , 'first_cat'
    , 'cat'
    , 'note'
    , 'viewAction'
    , 'editAction'
    , 'deleteAction'
  ];

  search_loading: boolean = false;
  updateFlg : boolean = false;
  pbVisible : boolean = false;

  tierLvl: KeyValue[] = [
    { key: 100, value: "1" },
    { key: 101, value: "1+" },
    { key: 200, value: "2" },
    { key: 300, value: "3" },
    { key: 999, value: "その他" }
  ]

  paginator = {
    page : 1,
    limit : '20',
    key : ''
  }

  pageItem = {
    first		      : 0
    , before	    : 0
    , current	    : 0
    , last		    : 0
    , next		    : 0
    , total_pages : 0
    , total_items : 0
    , limit		    : 0 
    , offset		  : 0 
    , items       : []
  }

  getTier(key: number){
    let ret: string
    for(let x = 0; x < this.tierLvl.length; x++){
      if(this.tierLvl[x].key === key) return this.tierLvl[x].value
    }
  }

  constructor(public itemService: ItemService, public dialog: MatDialog) { }
  
  ngOnInit() { 
    this.dataSource = new MatTableDataSource(this.items);
    this.getCategory()
    // this.itemPagination(this.paginator);
    this.getItems(this.msearch) 
    this.getCategories()
    this.getFirstCategories()
  }

  getCategories() {
    this.itemService.getCategories().subscribe(res => {
      this.category = res.payload.item_categories;
    });
  }

  getFirstCategories() {
    this.itemService.getFirstCategories().subscribe(res => {
      this.first_category = res.payload.first_item_categories;
    });
  }

  categs: Item_categ_list[] = [];

  isExisted(localValues, localProductCode) {
    for (var i = 0; i < localValues.length; ++i) {
        if (localValues[i].localProductCode == localProductCode) {
            return true;
        }
    }
    return false;
  }

  multipleStocksValue(item_id){ 
    if(this.multipleStocks.length){
      if(_.find(this.multipleStocks, function(o) { return o.item_id == item_id; })) {
        var stock = this.multipleStocks.filter(function (el) {
          return el.item_id === item_id;
        })[0].booked_stock; 
        return stock;
      }  
    }
    return null;
  }

  stockTotal(val1, val2){ 
    if(val1 && val2){
      return parseInt(val1) + parseInt(val2)
    }
    return null;
  }

  review(){

    if(this.multipleStocks.length < 1){
      return false;
    }

    let save = {
      update : this.multipleStocks
    }

    this.itemService.saveStock(save).subscribe(res=> { 
      console.log(res)
      if(res){
        this.multipleStocks = [];
        this.updateFlg = false;
      }
      this.getItems(this.msearch)
    })
  }
 

  onFlagChange(event : any, data : any) {   
      let value = event.target.value;
      if(value){ 
        if(this.multipleStocks.length) {
          if(!_.find(this.multipleStocks, {item_id: data.item_id})) {
            this.multipleStocks.push({
              item_id        : data.item_id
              , booked_stock : value
              , name         : data.name
              , old_booked_stock : data.booked_stock
              });
            } else { 
              _.remove(this.multipleStocks, function (e) { return e.item_id == data.item_id });
              this.multipleStocks.push({
                item_id        : data.item_id
                , booked_stock : value
                , name         : data.name
                , old_booked_stock : data.booked_stock
              })
            } 
          } else { 
            this.multipleStocks.push({
              item_id        : data.item_id
              , booked_stock : value
              , name         : data.name
              , old_booked_stock : data.booked_stock
            })
          } 
        event.target.classList.add('stockflg');
      } else {
        _.remove(this.multipleStocks, function (e) { return e.item_id == data.item_id });
              this.multipleStocks.push({
                item_id        : data.item_id
                , booked_stock : value
                , name         : data.name
                , old_booked_stock : data.booked_stock
              })
        event.target.classList.remove('stockflg'); 
        
      } 
  }

  // categs: Item_categ_list[] = []
  getCategory(){
    this.itemService.getCategories()
    .subscribe(res => {
      this.pbVisible = true
      console.log('getCategory', res)
      this.pbVisible = true
      this.categs = res.payload.item_categories
      // this.getItems();
    })
  }


  getCategName(id: number): string{
    for(let tmp of this.categs)
      if(tmp.cat_no === id) return tmp.cat_name
    return ''
  }

  getFirstCategName(id: number): string{
    for(let tmp of this.first_category)
      if(tmp.first_cat_no === id) return tmp.first_cat_name
    return ''
  }

  getItems(tosearch: string){
    this.search_loading = true
    this.msearch = tosearch.trimLeft()
    this.msearch = this.msearch.trimRight()
    console.log('getItems', this.offset, this.limit)
    this.itemService.getItems(this.msearch, '' + this.offset, this.limit).subscribe(res => {
      console.log('getItems', res)
      this.pbVisible = true
      this.search_loading = false
      if(res.success){
        this.items = res.payload.items
        this.total = res.payload.total.total

        if(this.items.length !== 0){
          for(let x = 0; x < this.items.length; x++){
            if(this.items[x].img == "" || this.items[x].img == " " || this.items[x].img == null || this.items[x].img == 'undefined'){
              this.items[x].img = ""
            }
          }
          this.dataSource.data = this.items;
          this.dataSource.filterPredicate = (data: Items, filters: string) =>{
            const matchFilter = [];
            const filterArray = filters.split(' ')
            const columns = [data.item_no.toString(), data.name, data.price.toString(), data.booked_stock.toString()]

            filterArray.forEach(filter =>{
              const customFilter = [];
              columns.forEach(column => customFilter.push(column.toLowerCase().includes(filter)));
              matchFilter.push(customFilter.some(Boolean));
            })
            return matchFilter.every(Boolean);
          }
        }
      }
    })
  }

  limitChange(){
    // let o = offset, l = limit, t = total, N = new offset
    // N = o - ( ( o + l ) - t )
    this.offset = Math.max(0, this.offset - Math.max(0, (this.offset + (+this.limit)) - this.total))
    this.getItems(this.msearch)
  }

  actionPage(action: number){
    if(action == 1){ // Fist Page
      this.offset = 0
      this.getItems(this.msearch)
    }else if(action == 2){ // Previous Page
      let tmp = this.offset - (+this.limit)
      if(this.offset == 0) return
      this.offset = tmp <= 0 ? 0 : tmp
      this.getItems(this.msearch)
    }else if(action == 3){ // Next Page
      let tmp = this.offset + (+this.limit)
      if(tmp >= this.total) return
      this.offset = tmp
      this.getItems(this.msearch)
    }else if(action == 4){ // Last Page
      let ttl = this.total - (+this.limit)
      this.offset = ttl <= 0 ? 0 : ttl
      this.getItems(this.msearch)
    }
  }

  tmpItem: Items[];
  filterValue_FirstCat : any;
  filterValue_SecondCat : any;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  applyFilterByCat(filterValue_SecondCat: any) {
    this.filterValue_SecondCat = filterValue_SecondCat;
    this.applyFilterTwoCategories()
  }

  applyFilterByFirstCat(filterValue_FirstCat: any) {
   this.filterValue_FirstCat = filterValue_FirstCat;
    this.applyFilterTwoCategories()
  }

  applyFilterTwoCategories() {
    this.tmpItem = [];
    if (this.filterValue_FirstCat === 'ALL' && this.filterValue_SecondCat === 'ALL') {
      this.tmpItem = this.items
    }
    // 1st Cat ALL ; 2nd Cat ANY
    else if (this.filterValue_FirstCat === 'ALL' &&  this.filterValue_SecondCat != 'ALL') {
      for (let x = 0; x < this.items.length; x++) {
        if (this.items[x].cat === this.filterValue_SecondCat) {
          this.tmpItem.push(this.items[x])
        }
      }
    }
    // 1st Cat ANY ; 2nd Cat ALL
    else if (this.filterValue_FirstCat != 'ALL' &&  this.filterValue_SecondCat === 'ALL') {
      for (let x = 0; x < this.items.length; x++) {
        if (this.items[x].first_cat === this.filterValue_FirstCat) {
          this.tmpItem.push(this.items[x])
        }
      }
    }
    // 1st Cat ANY ; 2nd Cat ANY 
    else if (this.filterValue_FirstCat != 'ALL' &&  this.filterValue_SecondCat != 'ALL') {
      for (let x = 0; x < this.items.length; x++) {
        if (this.items[x].first_cat === this.filterValue_FirstCat && this.items[x].cat === this.filterValue_SecondCat) {
          this.tmpItem.push(this.items[x])
        }
      }
    }
    this.dataSource.data = this.tmpItem
  }

  getSuffix(key: number) {
    key.toString();
    return this.itemService.sufList[key].value;
  }

  openDiaog(val: Items){ 
    this.dialog.open(ItemViewComponent, { width: '980px', height:'730px',
    data: {
      item_id: val.item_id,
      item_no: val.item_no,
      barcode: val.barcode,
      name: val.name,
      spec1: val.spec1,
      spec2: val.spec2,
      spec3: val.spec3,
      spec4: val.spec4,
      spec5: val.spec5,
      price: val.price,
      availability: val.availability,
      int_stock: val.int_stock,
      booked_stock: val.booked_stock,
      stock_type: val.stock_type,
      img : val.img,
      utencil_size : val.utencil_size,
      pkg_size: val.pkg_size,
      acc_pkg_size: val.acc_pkg_size,
      tier : val.tier,
      retail: val.retail,
      carrier: val.carrier,
      cat: val.cat,
      first_cat: val.first_cat,
      acc: val.acc,
      remarks: val.remarks,
      min_lot: val.min_lot,
      suffix: val.suffix,
      ship_lot: val.ship_lot,
      possibility: val.possibility,
      mnfc: val.mnfc,
      started_date: val.started_date,
      exp_date: val.exp_date,
      note: val.note,
      categs: this.categs,
      first_categs: this.first_category,
      sub_imgs: val.sub_imgs,
      cat_name : this.getCategName(val.cat),
      first_cat_name : this.getFirstCategName(val.first_cat)
    },
    })
  }

  openOptionDialog(opt: string, val: Items){
    console.log('val', val)
    if(opt === 'edit'){
      const dialogRef = this.dialog.open(ItemOptComponent, {
        width: '980px', height:'730px',
        data: {
            option: opt,
            item_id: val.item_id,
            item_no: val.item_no,
            barcode: val.barcode,
            name: val.name,
            spec1: val.spec1,
            spec2: val.spec2,
            spec3: val.spec3,
            spec4: val.spec4,
            spec5: val.spec5,
            price: val.price,
            availability: val.availability,
            int_stock: val.int_stock,
            booked_stock: val.booked_stock,
            stock_type: val.stock_type,
            img : val.img,
            utencil_size : val.utencil_size,
            pkg_size: val.pkg_size,
            acc_pkg_size: val.acc_pkg_size,
            tier : val.tier,
            retail: val.retail,
            carrier: val.carrier,
            cat: val.cat,
            first_cat: val.first_cat,
            acc: val.acc,
            remarks: val.remarks,
            min_lot: val.min_lot,
            suffix: val.suffix,
            ship_lot: val.ship_lot,
            possibility: val.possibility,
            mnfc: val.mnfc,
            started_date: val.started_date,
            exp_date: val.exp_date,
            note: val.note,
            categs: this.categs,
            first_categs: this.first_category,
            sub_imgs: val.sub_imgs
          },
        disableClose: true
      })
      dialogRef.afterClosed().subscribe(res => {
        if(res) this.getItems(this.msearch)
      })
    }else{
      const dialogRef = this.dialog.open(ItemOptComponent, {
        width: '980px', height:'730px' ,    
        data: {
            option: opt,
            item_no: val.item_no,
            barcode: val.barcode,
            name: val.name,
            spec1: val.spec1,
            spec2: val.spec2,
            spec3: val.spec3,
            spec4: val.spec4,
            spec5: val.spec5,
            price: val.price,
            int_stock: val.int_stock,
            booked_stock: val.booked_stock,
            stock_type: val.stock_type,
            img : val.img,
            utencil_size : val.utencil_size,
            pkg_size: val.pkg_size,
            acc_pkg_size: val.acc_pkg_size,
            tier : val.tier,
            retail: val.retail,
            carrier: val.carrier,
            cat: val.cat,
            first_cat: val.first_cat,
            acc: val.acc,
            remarks: val.remarks,
            min_lot: val.min_lot,
            suffix: val.suffix,
            ship_lot: val.ship_lot,
            possibility: val.possibility,
            mnfc: val.mnfc,
            started_date: val.started_date,
            exp_date: val.exp_date,
            note: val.note,
            categs: this.categs,
            first_categs: this.first_category,
            sub_imgs: val.sub_imgs 
          } ,
        disableClose: true
      })
      dialogRef.afterClosed().subscribe(res => {
        if(res) this.getItems(this.msearch)
      })
    }
  }

  openOptionDialogAdd(opt: string){
    {
      const dialogRef = this.dialog.open(ItemOptComponent, {
        width: '980px', height:'730px',
        data: {
            option: opt,
            item_no: '',
            barcode: '',
            name: '',
            spec1: '',
            spec2: '',
            spec3: '',
            spec4: '',
            spec5: '',
            price: '',
            int_stock: '',
            booked_stock: '',
            stock_type: '',
            img : '',
            utencil_size : '',
            pkg_size: '',
            acc_pkg_size: '',
            tier : '',
            retail: '',
            carrier: '',
            cat: '',
            first_cat:'',
            acc: '',
            remarks: '',
            min_lot: '',
            suffix: '',
            ship_lot: '',
            possibility: '',
            mnfc: '',
            started_date: '',
            exp_date: '',
            categs: this.categs,
            first_categs: this.first_category
          } ,
        disableClose: false
      })
      dialogRef.afterClosed().subscribe(res => {
        if(res) this.getItems(this.msearch)
      })
    }
  }

  showError:boolean = false;
  err_code:string = '';
  err_msg:string = '';

  onDelete(val: Items){

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {   
      width: '500px',
      data: {
        name: val.name
      }
    })
    dialogRef.afterClosed().subscribe(res => {
      if(res){
        let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
        let body = {
          'item_id': val.item_id,
          'deleted_by': tmp.userid 
        }
        this.itemService.saveItem(body, 'delete')
        .subscribe(res => {
          this.getItems(this.msearch)
        })
      }

      this.getItems(this.msearch)
    })
  }

}
