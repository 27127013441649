import { Injectable } from '@angular/core';
import { DataService, inquiryUrl, addChainUrl, saveTaskUrl }  from '../../shared/data.service';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent, HttpEventType ,HttpParams, HttpResponse} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import 'rxjs/add/observable/throw';
import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})

export class InquiryService {
  
  public handleError<T> (operation = 'operation', result?: T) {
    return (response: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      this.error(response);

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${response.message}`);

      // Let the app keep running by returning an empty result.
      // return of(result as T);
      return of(response.error as T);
    };
  }

  public error(message: string) { if (this.enableLog) console.error(message); }
  public log(message: any) { if (this.enableLog) console.log(message); }
  public logController(message: string): void { if (this.enableLog) console.log(message); }
  tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))


  enableLog: boolean;

  constructor(
    private dataService: DataService,
    public http: HttpClient
    ) { this.enableLog = true }
  

  setInquiry(body:any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    //const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let params: any
    params = new HttpParams()
      .set('store_id', body.store_id)
      .set('details', body.details)
      .set('urg_flg', body.urg_flg)
      .set('user_id', this.tmp.userid)
      .set('esc_direction',body.esc_direction)
      .set('esc_status',body.esc_status);

    return this.http.post(inquiryUrl, params.toString(), { headers,responseType: 'json' })
      .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
      .catch((err: any) => Observable.of(false));
  }

  updateEsc(body:any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let escurl = inquiryUrl + 'esc';

    body = new HttpParams()
      .set('inq_ref_no', body.inq_ref_no)
      .set('esc_status', body.esc_status)
      .set('esc_direction', body.esc_direction)
      .set('updated_by', this.tmp.userid)

    return this.http.put(escurl, body.toString(), { headers, observe: 'response' })
      .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
      .catch((err: any) => Observable.of(false));
  }

  getChainName(id: string){
    return this.dataService.get(addChainUrl + '?chain_no=' + id, null)
  }

  saveTask(body: any){
    return this.dataService.saveTask(saveTaskUrl, body)
  }
}