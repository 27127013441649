import { ResetPasswordData } from './admin-users/reset-password/shared/reset-password.prov';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS }    from '@angular/common/http';
import { NgHttpLoaderModule } from 'ng-http-loader';

import { TokenInterceptor } from './../app/auth/token.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule, RoutingComponents, RoutingProviders } from './app-routing.module';

import { SharedModule } from './shared/shared.module';
import { DateModule } from './shared/date.module';

import { CoreModule } from './core/core.module';
import { CookieService } from 'ngx-cookie-service';

/** User-Management **/
import { UserManagementComponent } from './user-management/user-management.component';
import { ConfirmStoreDialogComponent } from './store/store-index/confirm-store-dialog/confirm-store-dialog.component';

/* ordering */
import { ItemDetailDialogComponent } from './order/order-input/item-detail-dialog/item-detail-dialog.component';
import { OrderData } from './order/shared/order.prov';
import { BeforeunloadGuard } from './order/shared/order.guard';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ItemsData } from './order/shared/__items.data';

import { InquiryData } from './inquiry/shared/inquiry.prov';

import { UserAddDialogComponent } from './user-management/user-add/user-add-dialog.component';
import { UserListComponent } from './user-management/user-list/user-list.component';
import { UserViewComponent } from './user-management/user-view/user-view.component';

/** Item-Management **/
import { ItemManagementComponent } from './item-management/item-management.component';
import { ItemListComponent } from './item-management/item-list/item-list.component';
import { ItemOptComponent } from './item-management/item-opt/item-opt.component';
import { ItemViewComponent } from './item-management/item-view/item-view.component';
import { RelationalRefData } from './order/shared/order.prov';

/** ItemCategFirst-Management **/
import { ItemCategFirstManagementComponent } from './item-categ-first-management/item-categ-first-management.component';
import { ItemCategFirstListComponent } from './item-categ-first-management/item-categ-first-list/item-categ-first-list.component';
import { ItemCategFirstOptComponent } from './item-categ-first-management/item-categ-first-opt/item-categ-first-opt.component';
import { ItemCategFirstViewComponent } from './item-categ-first-management/item-categ-first-view/item-categ-first-view.component';

/** ItemCateg-Management **/
import { ItemCategManagementComponent } from './item-categ-management/item-categ-management.component';
import { ItemCategListComponent } from './item-categ-management/item-categ-list/item-categ-list.component';
import { ItemCategOptComponent } from './item-categ-management/item-categ-opt/item-categ-opt.component';
import { ItemCategViewComponent } from './item-categ-management/item-categ-view/item-categ-view.component';

/** Carrier Management **/
import { CarrierManagementComponent } from './carrier-management/carrier-management.component';
import { CarrierViewComponent } from './carrier-management/carrier-view/carrier-view.component';
import { CarrierOptComponent } from './carrier-management/carrier-opt/carrier-opt.component';
import { CarrierListComponent } from './carrier-management/carrier-list/carrier-list.component';

/** Carrier First Category Management **/
import { CarrierFirstCatManagementComponent } from './carrier-first-cat-management/carrier-first-cat-management.component';
import { CarrierFirstCatViewComponent } from './carrier-first-cat-management/carrier-first-cat-view/carrier-first-cat-view.component';
import { CarrierFirstCatOptComponent } from './carrier-first-cat-management/carrier-first-cat-opt/carrier-first-cat-opt.component';
import { CarrierFirstCatListComponent } from './carrier-first-cat-management/carrier-first-cat-list/carrier-first-cat-list.component';

/** Carrier Second Category Management **/
import { CarrierSecondCatManagementComponent } from './carrier-second-cat-management/carrier-second-cat-management.component';
import { CarrierSecondCatViewComponent } from './carrier-second-cat-management/carrier-second-cat-view/carrier-second-cat-view.component';
import { CarrierSecondCatOptComponent } from './carrier-second-cat-management/carrier-second-cat-opt/carrier-second-cat-opt.component';
import { CarrierSecondCatListComponent } from './carrier-second-cat-management/carrier-second-cat-list/carrier-second-cat-list.component';

/** Carrier Third Category Management **/
import { CarrierThirdCatManagementComponent } from './carrier-third-cat-management/carrier-third-cat-management.component';
import { CarrierThirdCatViewComponent } from './carrier-third-cat-management/carrier-third-cat-view/carrier-third-cat-view.component';
import { CarrierThirdCatOptComponent } from './carrier-third-cat-management/carrier-third-cat-opt/carrier-third-cat-opt.component';
import { CarrierThirdCatListComponent } from './carrier-third-cat-management/carrier-third-cat-list/carrier-third-cat-list.component';

/** Store Second Category Management **/
import { StoreSecondCatManagementComponent } from './store-second-cat-management/store-second-cat-management.component';
import { StoreSecondCatViewComponent } from './store-second-cat-management/store-second-cat-view/store-second-cat-view.component';
import { StoreSecondCatOptComponent } from './store-second-cat-management/store-second-cat-opt/store-second-cat-opt.component';
import { StoreSecondCatListComponent } from './store-second-cat-management/store-second-cat-list/store-second-cat-list.component';

/** Store Third Category Management **/
import { StoreThirdCatManagementComponent } from './store-third-cat-management/store-third-cat-management.component';
import { StoreThirdCatViewComponent } from './store-third-cat-management/store-third-cat-view/store-third-cat-view.component';
import { StoreThirdCatOptComponent } from './store-third-cat-management/store-third-cat-opt/store-third-cat-opt.component';
import { StoreThirdCatListComponent } from './store-third-cat-management/store-third-cat-list/store-third-cat-list.component';

/** Store Management **/
import { StoreManagementComponent } from './store-management/store-management.component';
import { StoreViewComponent } from './store-management/store-view/store-view.component';
import { StoreOptComponent } from './store-management/store-opt/store-opt.component';
import { StoreListComponent } from './store-management/store-list/store-list.component';

import { ChainManagementComponent } from './chain-management/chain-management.component';
import { ChainViewComponent } from './chain-management/chain-view/chain-view.component';
import { ChainOptComponent } from './chain-management/chain-opt/chain-opt.component';
import { ChainListComponent } from './chain-management/chain-list/chain-list.component';

import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { SuccessDialogComponent } from './shared/success-dialog/success-dialog.component';
import { DbWhComponent } from './dashboard/db-wh/db-wh.component';
import { DbHdComponent } from './dashboard/db-hd/db-hd.component';
import { DbCcComponent } from './dashboard/db-cc/db-cc.component';
import { InqConfirmComponent } from './dashboard/shared/inq-confirm/inq-confirm.component';
import { OrderConfirmComponent } from './dashboard/shared/order-confirm/order-confirm.component';
import { CollConfirmComponent } from './dashboard/shared/coll-confirm/coll-confirm.component';


import { AllInboundComponent } from './all-inbound/all-inbound.component';
import { InboundListComponent } from './all-inbound/inbound-list/inbound-list.component';
import { InboundViewComponent } from './all-inbound/inbound-view/inbound-view.component';
import { InboundViewOrderComponent } from './all-inbound//inbound-view-order/inbound-view-order.component';
import { InboundViewCollComponent } from './all-inbound//inbound-view-coll/inbound-view-coll.component';
import { InboundViewMainteComponent } from './all-inbound//inbound-view-mainte/inbound-view-mainte.component';

import { WorkflowSettingComponent } from './workflow_setting/workflow_setting.component'; //temporary
import { WaitingTaskComponent } from './waiting_task/waiting_task.component';
import { RedirectDashboardComponent } from './dashboard/redirect-dashboard/redirect-dashboard.component';
import { DbGgComponent } from './dashboard/db-gg/db-gg.component'; //temporary
import { DbVrComponent } from './dashboard/db-vr/db-vr.component';
import { DbDaComponent } from './dashboard/db-da/db-da.component';

import { UploadedDialogComponent } from './dashboard/db-wh/uploaded-dialog/uploaded-dialog.component';
import { CollectionConfirmComponent } from './collection/collection-confirm/collection-confirm.component';
import { CollectionCompleteComponent } from './collection/collection-complete/collection-complete.component';
import { MaintenanceCompleteComponent } from './maintenance/maintenance-complete/maintenance-complete.component';
import { MaintenanceOptComponent } from './maintenance/maintenance-opt/maintenance-opt.component';
import { MaintenanceData } from './maintenance/shared/maintenance.prov';
import { AngularFileUploaderModule } from "angular-file-uploader";

import { MatButtonModule } from '@angular/material';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { MaintenanceConfirmComponent } from './maintenance/maintenance-confirm/maintenance-confirm.component';

import { FileDropModule } from 'ngx-file-drop';
import { CollectionData } from './collection/shared/collection.prov';
import { ManualManagementComponent } from './manual-management/manual-management.component';
import { UploadFileComponent } from './manual-management/upload-file/upload-file.component';
import { VerifyComponent } from './admin-users/reset-password/verify/verify.component';

/* Vender */
import { VenderManagementComponent } from './vender-management/vender-management.component';
import { VenderListComponent } from './vender-management/vender-list/vender-list.component';
import { VenderOptComponent } from './vender-management/vender-opt/vender-opt.component';
import { VenderViewComponent } from './vender-management/vender-view/vender-view.component';
import { MaintenanceOptionComponent } from './dashboard/shared/maintenance-option/maintenance-option.component';
import { EditOrder } from './shared/edit-order.prov';
import { ReviewStockComponent } from './item-management/review-stock/review-stock.component';
import { DownloadTableCsvComponent } from './download-table-csv/download-table-csv.component';
import { UploadTableCsvComponent } from './upload-table-csv/upload-table-csv.component';

import { Angular2UsefulSwiperModule } from 'angular2-useful-swiper';
import { NguCarouselModule } from '@ngu/carousel';
import { NgxCarouselModule } from 'ngx-carousel';


@NgModule({
  declarations: [
    AppComponent,
    RoutingComponents,
    ConfirmStoreDialogComponent,
    ItemDetailDialogComponent,
    UserManagementComponent,UserAddDialogComponent,UserListComponent,UserViewComponent,
    StoreManagementComponent,StoreViewComponent,StoreOptComponent,StoreListComponent,
    ItemManagementComponent,ItemListComponent,ItemOptComponent,ItemViewComponent,
    ItemCategFirstManagementComponent,ItemCategFirstListComponent,ItemCategFirstOptComponent,ItemCategFirstViewComponent,
    ItemCategManagementComponent,ItemCategListComponent,ItemCategOptComponent,ItemCategViewComponent,
    ConfirmDialogComponent, SuccessDialogComponent, InqConfirmComponent, OrderConfirmComponent,
    ChainManagementComponent,ChainViewComponent,ChainOptComponent,ChainListComponent,
    StoreSecondCatManagementComponent,StoreSecondCatViewComponent,StoreSecondCatOptComponent,StoreSecondCatListComponent,
    StoreThirdCatManagementComponent,StoreThirdCatViewComponent,StoreThirdCatOptComponent,StoreThirdCatListComponent,

    UploadedDialogComponent,
    VenderManagementComponent,VenderListComponent,VenderOptComponent,VenderViewComponent,
    CollConfirmComponent,
    CarrierManagementComponent,CarrierViewComponent,CarrierOptComponent,CarrierListComponent,
    CarrierFirstCatManagementComponent,CarrierFirstCatViewComponent,CarrierFirstCatOptComponent,CarrierFirstCatListComponent,
    CarrierSecondCatManagementComponent,CarrierSecondCatViewComponent,CarrierSecondCatOptComponent,CarrierSecondCatListComponent,
    CarrierThirdCatManagementComponent,CarrierThirdCatViewComponent,CarrierThirdCatOptComponent,CarrierThirdCatListComponent,

    CollConfirmComponent,
    ConfirmDialogComponent,
		SuccessDialogComponent,
    AllInboundComponent,
    InboundListComponent,
    InboundViewComponent,
    InboundViewOrderComponent, InboundViewCollComponent,InboundViewMainteComponent,
    WorkflowSettingComponent, WaitingTaskComponent,
    RedirectDashboardComponent, DbWhComponent, DbHdComponent, DbCcComponent, DbGgComponent, DbVrComponent,DbDaComponent,
    CollectionConfirmComponent, CollectionCompleteComponent,
    MaintenanceCompleteComponent, MaintenanceOptComponent, MaintenanceConfirmComponent,
    ManualManagementComponent,
    UploadFileComponent,
    VerifyComponent,
    MaintenanceOptionComponent,
    ReviewStockComponent,
    DownloadTableCsvComponent,
    UploadTableCsvComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    SharedModule, DateModule,
    AngularFileUploaderModule,
    MatButtonModule,
    MatFileUploadModule,
    FileDropModule,
    Angular2UsefulSwiperModule,NguCarouselModule , NgxCarouselModule
    //InMemoryWebApiModule.forRoot(ItemsData, { passThruUnknownUrl: true }),
  ],
  providers: [
    RoutingProviders,
    RoutingProviders,
    CookieService,
    OrderData,
    InquiryData,
    CollectionData,
    MaintenanceData,
    BeforeunloadGuard,
    RoutingProviders,RelationalRefData,
    ResetPasswordData,
    EditOrder,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],

  entryComponents: [ // Dialog or Pop up componentes
    UserViewComponent, UserAddDialogComponent ,
    CarrierViewComponent, CarrierOptComponent,
    CarrierFirstCatViewComponent, CarrierFirstCatOptComponent,
    CarrierSecondCatViewComponent, CarrierSecondCatOptComponent,
    CarrierThirdCatViewComponent, CarrierThirdCatOptComponent,
    StoreViewComponent, StoreOptComponent,
    ItemOptComponent,ItemViewComponent,
    ItemCategFirstOptComponent,ItemCategFirstViewComponent,
    ItemCategOptComponent,ItemCategViewComponent,
    ChainViewComponent,ChainOptComponent,
    StoreSecondCatViewComponent, StoreSecondCatOptComponent,
    StoreThirdCatViewComponent, StoreThirdCatOptComponent,
    MaintenanceOptComponent,
    ConfirmStoreDialogComponent,ItemDetailDialogComponent,
    ConfirmDialogComponent, SuccessDialogComponent, InqConfirmComponent, OrderConfirmComponent,
    ConfirmDialogComponent, SuccessDialogComponent, InboundViewComponent,
    InboundViewOrderComponent,UploadedDialogComponent, UploadFileComponent,InboundViewCollComponent,InboundViewMainteComponent,
    VenderOptComponent, VenderViewComponent,
    CollConfirmComponent,MaintenanceOptionComponent,ReviewStockComponent
  ]
})
export class AppModule { }
