import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Items } from '../../item-management/shared/item.model';
import { CarrierSecondCatlist } from '../../carrier-second-cat-management/shared/carrier-second-cat.model';

@Component({
  selector: 'app-carrier-second-cat-view',
  templateUrl: './carrier-second-cat-view.component.html',
  styleUrls: ['./carrier-second-cat-view.component.scss']
})
export class CarrierSecondCatViewComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CarrierSecondCatViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CarrierSecondCatlist) { }

  ngOnInit() {
  }

}
