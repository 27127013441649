import { Injectable } from '@angular/core';
import { DataService } from '../../shared/data.service';
import { venderUrl, addVenderUrl, delVenderUrl } from './../../shared/data.service';

@Injectable({
  providedIn: 'root'
})
export class VenderService {

  constructor(private dataService: DataService) { }

  getVenderList(){
    return this.dataService.get(venderUrl, null);
  }
  venderOpt(body: any, opt :string){
    if(opt === 'delete')
      return this.dataService.venderOpt(delVenderUrl, body, opt)
    else
      return this.dataService.venderOpt(addVenderUrl, body, opt)
  }
}
