import { element } from 'protractor';
import { Component, OnInit,ViewChild } from '@angular/core'; 
import { UploadEvent, UploadFile, FileSystemDirectoryEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { MatDialogRef } from '@angular/material';
import { MyImage } from '../shared/MyImage.model';

@Component({
  selector: 'app-maintenance-opt',
  templateUrl: './maintenance-opt.component.html',
  styleUrls: ['./maintenance-opt.component.scss']
})
export class MaintenanceOptComponent implements OnInit {

  public files: UploadFile[] = [];
  imgs: MyImage[] = []
  mfiles: File[] =[]
 
  constructor(public dialogRef: MatDialogRef<MaintenanceOptComponent>) { }

  ngOnInit() {
  }

  img: any = ''
  public dropped(event: UploadEvent,) {
    this.files = event.files;
    console.log(this.files)
    // this.img = this.files[0].fileEntry.

    for (const droppedFile of event.files) {
 
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          
          var reader = new FileReader();
          reader.onload = (e: any) => {
            const f = file
            this.mfiles.push(f)
            this.imgs.push({
              img: e.target.result,
              name: f.name,
              modif: f.lastModified,
              size: f.size
            })
          }
          reader.readAsDataURL(file);

          // Here you can access the real file
          // console.log(fileEntry.file.)
          
          // You could upload it like this:
          // const formData = new FormData()
          // formData.append('logo', droppedFile.fileEntry.name)
 
          // Headers
          // const headers = new HttpHeaders({
          //   'security-token': 'mytoken'
          // })
 
          // this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
          // .subscribe(data => {
          //   // Sanitized logo returned from backend
          // })
          

        });
      // this.img = this.imgs[0]
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }

    console.log(this.imgs)
  }

  public del(x: number){
    this.mfiles.splice(x, 1)
    this.imgs.splice(x, 1)
  }

  public close(){
    this.dialogRef.close(this.mfiles)
  }

}
