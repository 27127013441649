import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ItemService } from '../shared/item.service';

@Component({
  selector: 'app-review-stock',
  templateUrl: './review-stock.component.html',
  styleUrls: ['./review-stock.component.scss']
})
export class ReviewStockComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ReviewStockComponent> 
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private service: ItemService,
  ) { }

  ngOnInit() {
    console.log(this.data)
  }

  onSubmit(){
    let save = {
      update : this.data
    }

    this.service.saveStock(save).subscribe(res=> { 
      console.log(res)
      if(res.success) {
        this.dialogRef.close(true)
      }
    })
  }

}
