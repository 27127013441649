import { Component, OnInit,ViewChild, OnDestroy,Input,HostListener } from '@angular/core';
import { Router, ActivatedRoute,Params } from '@angular/router';
import { Store } from '../../store/shared/store.model';
import { StoreService } from '../../store/shared/store.service';
import { Inquiry } from '../shared/inquiry.model';
import { InquiryService } from '../shared/inquiry.service';
import { InquiryData } from '../shared/inquiry.prov';
import { RelationalRefData } from 'src/app/order/shared/order.prov';
import { BadgeSocketService } from 'src/app/shared/badge-socket.service';

@Component({
  selector: 'app-inquiry-input-child',
  templateUrl: './inquiry-confirm.component.html',
  styleUrls: ['./inquiry-confirm.component.scss'],
  providers: [ StoreService, InquiryService ]
})

export class InquiryConfirmComponent implements OnInit {
  
  id:string;
  inquiry: Inquiry;
  store: Store[] = [];
  esc_dir_name:string ='';
  hasData:boolean = false;
  isLoading= false;
  btnDisable = false

  esc = [
    { key: 7, value: "なし"},
    { key: 0, value: "コールセンター" },
    { key: 2, value: "博報堂" },
    { key: 4, value: "倉庫" },
    { key: 1, value: "ラウンダー" }
    
  ];
  
  constructor(
    public badgeService: BadgeSocketService,
    private route: ActivatedRoute,
    private router: Router,
    private storeService: StoreService,
    private inquiryService: InquiryService,
    private inquiryData: InquiryData,
    private rel: RelationalRefData,
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.store = this.inquiryData.store;
    this.inquiry = this.inquiryData.inquiry;

    if(this.inquiryData.store){
      this.hasData = true;
    }

    switch(this.inquiry.esc_direction){
      case 0:
      this.esc_dir_name ="コールセンター";
      break;
      case 1:
      this.esc_dir_name = "ラウンダー";
      break;

      case 2:
      this.esc_dir_name = "博報堂";
      break;

      case 3:
      this.esc_dir_name = "Google";
      break;

      case 4:
      this.esc_dir_name = "倉庫";
      break;

      default:
      this.esc_dir_name = "なし";
    }
  }

  onBack(){
    this.inquiryData.inquiry = this.inquiry
    this.router.navigate(['/main/inquiry/input/' + this.id]);
  }

  showError:boolean = false;
  err_code = "";
  err_msg = "";
  queryInquiry:any;
  escQuery:any;

  toComplete(){
    this.btnDisable = true
    this.isLoading = true;
    this.queryInquiry = {
      'store_id': this.id,
      'details': this.inquiry.detail,
      'urg_flg': this.inquiry.uFlag === true ? 1 : 0,
      'esc_status': this.inquiry.uFlag === true ? 3 : 1,
      'esc_direction': this.inquiry.esc_direction,
    }

    console.log('fasdfasdfas', this.inquiry.esc_direction)

    let result:any;
    
    let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))

    this.inquiryService.setInquiry(this.queryInquiry)
    .subscribe(
      res => {
        this.btnDisable = false
        var result = JSON.parse(JSON.stringify(res));
        this.inquiryData.inquiry = this.inquiry;
        this.inquiryData.store = this.store;
        this.inquiryData.ref_code = result.payload.inquiryRefNo;
        this.rel.inq_ref = result.payload.inquiryRefNo;

        var body = {
          'head': this.inquiry.esc_direction == 7 ? 1 : 0, //0,
          'reference': result.payload.inquiryRefNo,
          'type' : 1,
          'user_type': this.inquiry.esc_direction,
          'user': tmp.userid,
          'level': this.inquiry.esc_direction == 7 ? 2 : 1, //1,
          'user_id': tmp.userid,
          'cc_view': this.inquiry.esc_direction == 0 ? 1 : 0, //1,
          'hd_view': this.inquiry.esc_direction == 2 ? 1 : 0,
          'wh_view': this.inquiry.esc_direction == 4 ? 1 : 0,
          'rr_view': this.inquiry.esc_direction == 1 ? 1 : 0,  
        }

        this.inquiryService.saveTask(body)
        .subscribe(ret => {
          let view_tmp = {
            'cc': this.inquiry.esc_direction == 0,   //true,  
            'hd': this.inquiry.esc_direction == 2,
            'wh': this.inquiry.esc_direction == 4,
            'rr': this.inquiry.esc_direction == 1,
            'gg': false,
            'vr': false
          }
          //this.badgeService.pushBadgeNotif(view_tmp)
          this.router.navigate(['main/inquiry/complete/' + this.id]);
        })
        // this.inquiryData.ref_code = result.payload.inquiryRefNo;
      }
    );
  }
}
