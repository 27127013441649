import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Vender_list } from '../shared/venders.model';
import { ItemCategViewComponent } from 'src/app/item-categ-management/item-categ-view/item-categ-view.component';
import { Item_categ_list } from 'src/app/item-categ-management/shared/item-categ.model';

@Component({
  selector: 'app-vender-view',
  templateUrl: './vender-view.component.html',
  styleUrls: ['./vender-view.component.scss']
})
export class VenderViewComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<VenderViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Vender_list) { }

  ngOnInit() {
  }

}
