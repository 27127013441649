import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MediaMatcher } from '@angular/cdk/layout';
import { MatButtonToggle, MatSidenav } from '@angular/material';

import { SidenavService } from '../shared/sidenav.service';

import { AuthService  } from '../admin-users/shared/auth.service';
import { COMPANY_NAME, COMPANY_NAME_SUB } from '../shared/data.service';
import { UserListService } from '../user-management/shared/user-list.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  public companyName = COMPANY_NAME;
  public companyNameSub = COMPANY_NAME_SUB;
  
  @ViewChild('sidenav') sidenav: MatSidenav;

  @ViewChild('dashboard') dashboardButton: MatButtonToggle;
  @ViewChild('item') itemButton: MatButtonToggle;
  @ViewChild('user') userButton: MatButtonToggle;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  currentUser: any;
  currentUrl: string;
  isAlreadyUpdated: boolean = false;
  userDesc = JSON.parse(sessionStorage.getItem('currentUser'))

  btnId: number = 1;
  mngt_setting : any;
  username: string;
  profilepic : string;
  constructor(
    public userservice: UserListService,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef, 
    public media: MediaMatcher, 
    private route: ActivatedRoute, 
    public sidenavService: SidenavService,
    private router: Router) {
      this.mobileQuery = media.matchMedia('(max-width: 800px)');
      this._mobileQueryListener = () => {
        if (this.mobileQuery.matches) {
          this.sidenavService.setMenuVisible(true);
        } else {
          this.sidenavService.setMenuVisible(false);
        }
        changeDetectorRef.detectChanges();
      };

      this.mobileQuery.addListener(this._mobileQueryListener);
      this.currentUser = authService.currentUser   
      this.username = authService.currentUser.username   
  }

  ngOnInit() {
    this.userservice.getUserList().subscribe(res => {
      if(res.success){
        let users = res.payload.users
        for(let user of users){
          if (user.user_no == this.currentUser.userid){
            if (user.icon == "" || user.icon == "123" || user.icon == null || user.icon == " "){
              this.profilepic = "" 
              
            }else{
              this.profilepic = user.icon.toString()
            }
          }
        }
      }

    })

    // 0 = ENABLE/SHOW ; 1 = DISABLE/HIDE

    this.mngt_setting = {
      "home":"0",                   // 1 ホーム
      "waiting_task":"0",           //  対応待ちタスク
      "input_inquiry":"0",          // 2 問い合わせ入力
      "input_order_req":"0",        // 3 受注依頼入力
      "input_coll_req":"0",         // 4 回収依頼入力
      "input_main_req":"0",         // 5 メンテナンス依頼入力
      "all_inbound_record":"0",     // 6 全受電履歴
      "coop_mngt":"0",              // 7 量販大分類
      "store_second_cat":"0",       // 8 量販中分類
      "store_third_cat":"0",        // 9 量販小分類
      "store_mngt":"0",             // 10 店舗管理
      "carrier_first_cat":"0",      // 11 キャリア大分類
      "carrier_second_cat":"0",     // 12 キャリア中分類
      "carrier_third_cat":"0",      // 13 キャリア小分類
      "carrier_mngt":"0",           // 14 キャリア管理
      "user_mngt":"0",              // 15 ユーザー管理
      "itemcateg_mngt_first":"0",   // 16 商品大カテゴリー管理
      "itemcateg_mngt_second":"0",  // 17 商品 小カテゴリー管理理
      "item_mngt":"0",              // 18 アイテム管理 
      "vender_mngt":"0",            // 19 ベンダー管理
      "workflow_settings":"0",      // 20 ワークフロー設定
      "manual_mngt":"0",            // 21 マニュアル
      "alldata_mngt":"1",           // 22 すべてのデータ
      "csvup_mngt":"1"              // 23 CSVをアップロード 

    }
    /* 
    Management type or User Role:  
    0 = Admin; 
    1 = General User;
    Usertype:
    0 - Call Center; 
    1 - Rounder; 
    2 - HAKUHOUDOU; 
    3 - Google; 
    4 - WAREHOUSE; 
    5 - VENDOR; 
    6 - SHOP;
    7 - Data Admin/データ管理者 ;
    */

    if(this.currentUser.userrole == 0 && this.currentUser.usertype == 0 ){ // Call Center Admin
      this.mngt_setting.input_order_req = "0"
      this.mngt_setting.input_coll_req = "1"
      this.mngt_setting.input_main_req = "1"
    }

    else if(this.currentUser.userrole == 1 && this.currentUser.usertype == 0 ){ // Call Center General
      this.mngt_setting.input_order_req = "0"
      this.mngt_setting.input_coll_req = "1"
      this.mngt_setting.input_main_req = "1"
      this.mngt_setting.coop_mngt = "1"
      this.mngt_setting.store_second_cat= "1"
      this.mngt_setting.store_third_cat= "1"
      this.mngt_setting.carrier_first_cat = "1"
      this.mngt_setting.carrier_second_cat = "1"
      this.mngt_setting.carrier_third_cat = "1"
      this.mngt_setting.user_mngt = "1"
      this.mngt_setting.itemcateg_mngt_first = "1"
      this.mngt_setting.itemcateg_mngt_second = "1"
      this.mngt_setting.workflow_settings = "1"
      this.mngt_setting.manual_mngt = "1"
      this.mngt_setting.vender_mngt = "1"
    }

    else if ((this.currentUser.userrole == 0 )&& this.currentUser.usertype == 1 ){ // Rounder Admin
      this.mngt_setting.input_inquiry = "0"
      this.mngt_setting.input_order_req = "0"
      this.mngt_setting.input_coll_req = "0"
      this.mngt_setting.input_main_req = "0"
      this.mngt_setting.coop_mngt = "0"
      this.mngt_setting.store_second_cat = "0"
      this.mngt_setting.store_third_cat = "0"
      this.mngt_setting.store_mngt = "0"
      this.mngt_setting.carrier_first_cat = "0"
      this.mngt_setting.carrier_second_cat = "0"
      this.mngt_setting.carrier_third_cat = "0"
      this.mngt_setting.carrier_mngt = "0"
      this.mngt_setting.user_mngt = "0"
      this.mngt_setting.itemcateg_mngt_first = "0"
      this.mngt_setting.itemcateg_mngt_second = "0"
      this.mngt_setting.item_mngt = "0"
      this.mngt_setting.workflow_settings = "0"
      this.mngt_setting.manual_mngt = "0"
      this.mngt_setting.vender_mngt = "0"
    }

    else if ((this.currentUser.userrole == 1 )&& this.currentUser.usertype == 1 ){ // Rounder General
      this.mngt_setting.input_inquiry = "0"
      this.mngt_setting.input_order_req = "0"
      this.mngt_setting.input_coll_req = "0"
      this.mngt_setting.input_main_req = "0"
      this.mngt_setting.coop_mngt = "1"
      this.mngt_setting.store_second_cat = "1"
      this.mngt_setting.store_third_cat = "1"
      this.mngt_setting.store_mngt = "1"
      this.mngt_setting.carrier_first_cat = "1"
      this.mngt_setting.carrier_second_cat = "1"
      this.mngt_setting.carrier_third_cat = "1"
      this.mngt_setting.carrier_mngt = "1"
      this.mngt_setting.user_mngt = "1"
      this.mngt_setting.itemcateg_mngt_first = "1"
      this.mngt_setting.itemcateg_mngt_second = "1"
      this.mngt_setting.item_mngt = "1"
      this.mngt_setting.workflow_settings = "1"
      this.mngt_setting.manual_mngt = "1"
      this.mngt_setting.vender_mngt = "1"
    }

    else if ((this.currentUser.userrole == 0 )&& this.currentUser.usertype == 2 ){ // Hakouhou Admin
      this.mngt_setting.input_inquiry = "0"
      this.mngt_setting.input_order_req = "0"
      this.mngt_setting.input_coll_req = "0"
      this.mngt_setting.input_main_req = "0"
      this.mngt_setting.coop_mngt = "0"
      this.mngt_setting.store_second_cat = "0"
      this.mngt_setting.store_third_cat = "0"
      this.mngt_setting.store_mngt = "0"
      this.mngt_setting.carrier_first_cat = "0"
      this.mngt_setting.carrier_second_cat = "0"
      this.mngt_setting.carrier_third_cat = "0"
      this.mngt_setting.carrier_mngt = "0"
      this.mngt_setting.user_mngt = "0"
      this.mngt_setting.itemcateg_mngt_first = "0"
      this.mngt_setting.itemcateg_mngt_second = "0"
      this.mngt_setting.item_mngt = "0"
      this.mngt_setting.workflow_settings = "0"
      this.mngt_setting.manual_mngt = "0"
      this.mngt_setting.vender_mngt = "0"
      this.mngt_setting.alldata_mngt = "0"
      this.mngt_setting.csvup_mngt = "0"
    }

    else if ((this.currentUser.userrole == 1 )&& this.currentUser.usertype == 2 ){ // Hakouhou General
      this.mngt_setting.input_inquiry = "0"
      this.mngt_setting.input_order_req = "0"
      this.mngt_setting.input_coll_req = "0"
      this.mngt_setting.input_main_req = "0"
      this.mngt_setting.coop_mngt = "1"
      this.mngt_setting.store_second_cat = "1"
      this.mngt_setting.store_third_cat = "1"
      this.mngt_setting.store_mngt = "1"
      this.mngt_setting.carrier_first_cat = "1"
      this.mngt_setting.carrier_second_cat = "1"
      this.mngt_setting.carrier_third_cat = "1"
      this.mngt_setting.carrier_mngt = "1"
      this.mngt_setting.user_mngt = "1"
      this.mngt_setting.itemcateg_mngt_first = "1"
      this.mngt_setting.itemcateg_mngt_second = "1"
      this.mngt_setting.item_mngt = "1"
      this.mngt_setting.workflow_settings = "1"
      this.mngt_setting.manual_mngt = "1"
      this.mngt_setting.vender_mngt = "1"
    }

    else if( (this.currentUser.userrole == 0 ) && this.currentUser.usertype == 4 ){ // Warehosue Admin
      this.mngt_setting.input_inquiry = "1"
      this.mngt_setting.input_order_req = "1"
      this.mngt_setting.input_coll_req = "1"
      this.mngt_setting.input_main_req = "1"
    }
    else if( ( this.currentUser.userrole == 1) && this.currentUser.usertype == 4 ){ // Warehosue General
      this.mngt_setting.input_inquiry = "1"
      this.mngt_setting.input_order_req = "1"
      this.mngt_setting.input_coll_req = "1"
      this.mngt_setting.input_main_req = "1"
      this.mngt_setting.coop_mngt = "1"
      this.mngt_setting.store_second_cat = "1"
      this.mngt_setting.store_third_cat = "1"
      this.mngt_setting.store_mngt = "1"
      this.mngt_setting.carrier_first_cat = "1"
      this.mngt_setting.carrier_second_cat = "1"
      this.mngt_setting.carrier_third_cat = "1"
      this.mngt_setting.carrier_mngt = "1"
      this.mngt_setting.user_mngt = "1"
      this.mngt_setting.itemcateg_mngt_first = "1"
      this.mngt_setting.itemcateg_mngt_second = "1"
      this.mngt_setting.item_mngt = "1"
      this.mngt_setting.workflow_settings = "1"
      this.mngt_setting.manual_mngt = "1"
      this.mngt_setting.vender_mngt = "1"
    }

    // VENDER
    else if( (this.currentUser.userrole == 0 ) && this.currentUser.usertype == 5 ){ // VENDER Admin
      this.mngt_setting.input_inquiry = "1"
      this.mngt_setting.input_order_req = "1"
      this.mngt_setting.input_coll_req = "1"
      this.mngt_setting.input_main_req = "1"
    }
    else if( ( this.currentUser.userrole == 1) && this.currentUser.usertype == 5 ){ // VENDER General
      this.mngt_setting.input_inquiry = "1"
      this.mngt_setting.input_order_req = "1"
      this.mngt_setting.input_coll_req = "1"
      this.mngt_setting.input_main_req = "1"
      this.mngt_setting.coop_mngt = "1"
      this.mngt_setting.store_second_cat = "1"
      this.mngt_setting.store_third_cat = "1"
      this.mngt_setting.store_mngt = "1"
      this.mngt_setting.carrier_first_cat = "1"
      this.mngt_setting.carrier_second_cat = "1"
      this.mngt_setting.carrier_third_cat = "1"
      this.mngt_setting.carrier_mngt = "1"
      this.mngt_setting.user_mngt = "1"
      this.mngt_setting.itemcateg_mngt_first = "1"
      this.mngt_setting.itemcateg_mngt_second = "1"
      this.mngt_setting.item_mngt = "1"
      this.mngt_setting.workflow_settings = "1"
      this.mngt_setting.manual_mngt = "1"
      this.mngt_setting.vender_mngt = "1"
    }

    else if( ( this.currentUser.userrole == 1) && this.currentUser.usertype == 3 ){ // Google General
      this.mngt_setting.input_inquiry = "1"
      this.mngt_setting.input_order_req = "1"
      this.mngt_setting.input_coll_req = "1"
      this.mngt_setting.input_main_req = "1"
      this.mngt_setting.coop_mngt = "1"
      this.mngt_setting.store_second_cat = "1"
      this.mngt_setting.store_third_cat = "1"
      this.mngt_setting.store_mngt = "1"
      this.mngt_setting.carrier_first_cat = "1"
      this.mngt_setting.carrier_second_cat = "1"
      this.mngt_setting.carrier_third_cat = "1"
      this.mngt_setting.carrier_mngt = "1"
      this.mngt_setting.user_mngt = "1"
      this.mngt_setting.itemcateg_mngt_first = "1"
      this.mngt_setting.itemcateg_mngt_second = "1"
      this.mngt_setting.item_mngt = "1"
      this.mngt_setting.workflow_settings = "1"
      this.mngt_setting.manual_mngt = "1"
      this.mngt_setting.vender_mngt = "1"
    }
    // DataAdmin 
    else if( (this.currentUser.userrole == 0 || this.currentUser.userrole == 1 ) && this.currentUser.usertype == 7 ){ // DataAdmin Admin or General
      this.mngt_setting.home = "0"
      this.mngt_setting.input_inquiry = "1"
      this.mngt_setting.input_order_req = "1"
      this.mngt_setting.input_coll_req = "1"
      this.mngt_setting.input_main_req = "1"
      this.mngt_setting.coop_mngt = "0" 
      this.mngt_setting.store_second_cat = "0"
      this.mngt_setting.store_third_cat = "0"
      this.mngt_setting.store_mngt = "0" 
      this.mngt_setting.carrier_first_cat = "0"
      this.mngt_setting.carrier_second_cat = "0"
      this.mngt_setting.carrier_third_cat = "0"
      this.mngt_setting.carrier_mngt = "0"
      this.mngt_setting.user_mngt = "1"
      this.mngt_setting.itemcateg_mngt_first = "1"
      this.mngt_setting.itemcateg_mngt_second = "1"
      this.mngt_setting.item_mngt = "1"
      this.mngt_setting.workflow_settings = "1"
      this.mngt_setting.manual_mngt = "1"
      this.mngt_setting.vender_mngt = "1"
      this.mngt_setting.all_inbound_record = "1"
      this.mngt_setting.alldata_mngt = "0"
      this.mngt_setting.csvup_mngt = "0"
    }

  }

  ngAfterViewInit() {
    // this.sidenavService.setSideNav(this.sidenav);
    // this.updateURL();

    // if (this.mobileQuery.matches) {
    //   this.sidenavService.setMenuVisible(true);
    //   console.log("matches")
    // } else {
    //   this.sidenavService.setMenuVisible(false);
    //   console.log("not-matches")
    // }
    // this.changeDetectorRef.detectChanges();
    setTimeout(()=>{
      this.drawerOpen = true;
      this.drawerShow = true;
      },0);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngDoCheck() {
    // if (this.isAlreadyUpdated) return;
    // this.isAlreadyUpdated = true;
    // this.updateURL();
  }

  updateURL() {
    let url = this.route.firstChild.snapshot.url.toString();
    if (url == this.dashboardButton.value) {
      this.dashboardButton.checked = true;
    } else if (url == this.itemButton.value) {
        this.itemButton.checked = true;
    } else if (url == this.userButton.value) {
      this.userButton.checked = true;
    } 

    this.currentUrl = url;
    this.changeDetectorRef.detectChanges();
  }

  goto(url: string) {
    if (this.currentUrl === url) return;
    this.isAlreadyUpdated = false;
    if(this.mobileQuery.matches) {
      this.sidenavService.closeSidenav();
    }
    this.router.navigate([`./${url}`], { relativeTo: this.route })
  }

  signout() {
    this.authService.signout();
    this.router.navigate(['/signin']);
  }

  drawerShow: boolean = false;
  drawerOpen: boolean = false;
  drawerToggle(){
    this.drawerShow = !this.drawerShow
    this.drawerOpen = !this.drawerOpen
  }
}
