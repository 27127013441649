import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(public snackBar: MatSnackBar) { }

  openSnackBar(message: string) {
    // Simple message.
    this.snackBar.open(message, '', {
      duration: 10000
    });
  }
}
