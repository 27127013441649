import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Items } from '../../item-management/shared/item.model';
import { Item_categ_list } from '../../item-categ-management/shared/item-categ.model';

@Component({
  selector: 'app-item-categ-view',
  templateUrl: './item-categ-view.component.html',
  styleUrls: ['./item-categ-view.component.scss']
})
export class ItemCategViewComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ItemCategViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Item_categ_list) { }

  ngOnInit() {
  }

}
