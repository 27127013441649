import { venderUrl } from './../shared/data.service';
import { Component, OnInit } from '@angular/core';
import { DownloadTableCsvService } from './shared/download-table-csv.service';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { ExportToCsv } from 'export-to-csv';
import { ExportUtility } from './../dashboard/shared/order-confirm/export-utility';
import { variable } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-download-table-csv',
  templateUrl: './download-table-csv.component.html',
  styleUrls: ['./download-table-csv.component.scss']
})
export class DownloadTableCsvComponent implements OnInit {

  fieldsFormGroup : FormGroup
  interests:any;
  selected: any;

  constructor(
    private service: DownloadTableCsvService, 
    private formBuilder: FormBuilder
    ) { }



  tables : any;
  fields : any;
  selectedTable : string;

  ngOnInit() {

    this.genform();
    this.getTable();
    console.log("this is tablessss",this.tables)
  }

  genform(){
    this.fieldsFormGroup = this.formBuilder.group({
      fields: this.formBuilder.array([])
    });
  }
 
  getTable(){
    this.service.getTable().subscribe(res => {
      if(res.success){
        this.tables = res.payload.tables;
        this.tables.push("retail_master")
      }
    })
    
    //this.tables.push("retail_master")
  }
  
  generateFields(table_value:any){
    this.service.getFields(this.selectedTable).subscribe(res => {
      if(res.success){
        this.fields = res.payload.fields;
        if(table_value == "store_master"){
        this.fields.splice(this.fields.indexOf("system01"), 1);
        this.fields.splice(this.fields.indexOf("TZ_zero_padding"), 1);
        this.fields.splice(this.fields.indexOf("wms_id"), 1);
        console.log(this.fields)
        }
        if(table_value == "retail_master"){
        this.fields.splice(this.fields.indexOf("system01"), 1);
        this.fields.splice(this.fields.indexOf("tier"), 1);
        this.fields.splice(this.fields.indexOf("TZ_zero_padding"), 1);
        this.fields.splice(this.fields.indexOf("wms_id"), 1);
        console.log(this.fields)
        }

        //if (table="retail_master"){
        //  this.fields.splice(this.fields.indexOf("TZ_zero_padding"), 1);
        //}

        const fields = <FormArray>this.fieldsFormGroup.get('fields') as FormArray;
        this.fields.forEach(function (v) {
          setTimeout(() => {
            console.log('timeout!');
          }, 60000);
          if (table_value.toString() == "store_master" && v.toString() == "system01" ){ return }
          if (table_value.toString() == "store_master" && v.toString() == "TZ_zero_padding" ) { return }
          if (table_value.toString() == "store_master" && v.toString() == "wms_id" ) { return } 
         
          else {
            setTimeout(() => {
              console.log('timeout!');
            }, 60000);
            fields.push(new FormControl(v))
          }
        }); 
      }
    })
  }
  

  getFields(table : any){ 
    console.log("selected table",table.value)
    if (table.value === "retail_master"){
      this.genform();
      this.selectedTable = "retail_master"
      this.fields = "";
    }
    else if (table.value != "retail_master"){
      this.genform();
      this.selectedTable = table.value;
      this.generateFields(table.value)
    }
    
  }

  onChange(event) {
    
    const fields = <FormArray>this.fieldsFormGroup.get('fields') as FormArray;

    if(event.checked) {
      fields.push(new FormControl(event.source.value))
    } else {
      const i = fields.controls.findIndex(x => x.value === event.source.value);
      fields.removeAt(i);
    }
  }
  getRetailMaster(){
    var data = {
      fields : this.fieldsFormGroup.value.fields.toString(),
      table : this.selectedTable
    }
    this.service.getRetailMasterData(data).subscribe(res => {
      if(res.success){ 
        const options = { 
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true, 
          showTitle: false,
          title: this.selectedTable,
          useBom: true,
          useKeysAsHeaders: true,
          filename : this.selectedTable
        };
        const csvExporter = new ExportToCsv(options);
        ExportUtility.downloadcsv(res.payload, "data_");
      }
    }) 
  }

  submit() {
    if(this.fieldsFormGroup.value.fields.length > 0){
 
      var data = {
        fields : this.fieldsFormGroup.value.fields.toString(),
        table : this.selectedTable
      }
      console.log(data);
        this.service.getData(data).subscribe(res => {
          if(res.success){ 
            const options = { 
              fieldSeparator: ',',
              quoteStrings: '"',
              decimalseparator: '.',
              showLabels: true, 
              showTitle: false,
              title: this.selectedTable,
              useBom: true,
              useKeysAsHeaders: true,
              filename : this.selectedTable
            };
            const csvExporter = new ExportToCsv(options);
            ExportUtility.downloadcsv(res.payload, "data_");
          }
        }) 
      }
    }
  
}
