import { addChainUrl, saveRelation, saveTaskUrl, updateOrder, addItemUrl } from './../../shared/data.service';
import { Injectable } from '@angular/core';
import { DataService, itemsUrl, categoriesUrl, setOrderUrl , first_categoriesUrl}  from '../../shared/data.service';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent, HttpEventType ,HttpParams, HttpResponse} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import 'rxjs/add/observable/throw';
import 'rxjs/Rx';

export const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})


export class OrderService {

  sufList = [
    { "value":"本" },
    { "value":"枚" },
    { "value":"冊" },
    { "value":"機" },
    { "value":"個" },
    { "value":"ケ" }
  ];

  mnList = [
    {"value":"メーカー"},
    {"value":"メーカー1"},
    {"value":"メーカー2"},
    {"value":"メーカー3"},
    {"value":"メーカー4"},
  ];

  enableLog: boolean;
  public handleError<T> (operation = 'operation', result?: T) {
    return (response: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      this.error(response);

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${response.message}`);

      // Let the app keep running by returning an empty result.
      // return of(result as T);
      return of(response.error as T);
    };
  }

  public error(message: string) { if (this.enableLog) console.error(message); }
  public log(message: any) { if (this.enableLog) console.log(message); }
  public logController(message: string): void { if (this.enableLog) console.log(message); }
  tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
  
  constructor(
    private dataService: DataService,
    public http: HttpClient) { this.enableLog = true }

  getItems(msearch: string, offset: string, limit: string) {
    return this.dataService.get(itemsUrl + '?mseach=' + msearch + '&offset=' + offset + '&limit=' + limit, null);
  }

  getItemById(id: any){
    return this.http.get<any[]>(addItemUrl + '/' + id).pipe(
      tap(any => this.log('fetched data')),
      catchError(this.handleError<any>('get', []))
    ) 
  }

  getCategories() {
    return this.dataService.get(categoriesUrl, null);
  }

  getFirstCategories(){
    return this.dataService.get(first_categoriesUrl, null);
  }

  setOrder(body:any) {
    return this.http.post<any>(setOrderUrl, body).pipe(
      tap((result: any) => this.log(`post data from ${setOrderUrl}`)),
      catchError(this.handleError<any>('post error'))
    )
  }

  updateOrder(body: any){
    return this.http.post<any>(updateOrder, body).pipe(
      tap((result: any) => this.log(`post data from ${setOrderUrl}`)),
      catchError(this.handleError<any>('post error'))
    )
  }

  getChainName(id: string){
    return this.dataService.get(addChainUrl + '?chain_no=' + id, null)
  }

  saveRelation(body: any){
    return this.dataService.postRelation(saveRelation, body)
  }

  updateRelation(body: any){
    return this.dataService.putRelation(saveRelation, body)
  }

  saveTask(body: any){
    return this.dataService.saveTask(saveTaskUrl, body)
  }
}

