import { UserAddDialogComponent } from './../user-add/user-add-dialog.component';
import { UserViewComponent } from '../user-view/user-view.component';
import { UserList } from '../shared/user-list-interface';
import { UserListService } from '../shared/user-list.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, Sort  } from '@angular/material';
import { formatDate } from '@angular/common';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { VenderService } from '../../vender-management/shared/vender.service';
import { Vender_list } from '../../vender-management/shared/venders.model';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  users: UserList[] = [];
  dataSource: MatTableDataSource<UserList>;
  displayedColumns = ['id', 'name', 'mail_address', 'viewAction', 'editAction', 'deleteAction'];
  pbVisible: boolean = false;
  venders: Vender_list[] = [];

  constructor(public service: UserListService, public dialog: MatDialog,public Venderservice: VenderService,) { }

  search_loading: boolean = false;
  paginator = {
    page : 1,
    limit : '20',
    key : ''
  }

  pageItem = {
    first		      : 0
    , before	    : 0
    , current	    : 0
    , last		    : 0
    , next		    : 0
    , total_pages : 0
    , total_items : 0
    , limit		    : 0 
    , offset		  : 0 
    , items       : []
  }


  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.users);
    // this.getUsers();
    // this.dataSource.sort = this.sort;
    this.getVender(); 
    this.userPagination(this.paginator);
  }

  userPagination(body){
    this.search_loading = true;
    this.service.userPagination(body).subscribe(res => {
      if(res.success){
        console.log(res)
        this.pageItem = res.payload;
        this.users    = res.payload.items;
        this.dataSource = new MatTableDataSource(this.users);
        this.dataSource.sort = this.sort;
      }
      this.pbVisible = true
      this.search_loading = false;
    });
  }
  
  someMethod(value){
    this.paginator.limit = value;
    this.userPagination(this.paginator); 
  }

  pageAction(action){
    switch(action){
      case 'first' : {
        this.paginator.page = this.pageItem.first
        break;
      }
      case 'prev' : {
        this.paginator.page = this.pageItem.before
        break;
      }
      case 'next' : {
        this.paginator.page = this.pageItem.next
        break;
      }
      case 'last' : {
        this.paginator.page = this.pageItem.last
        break;
      }
    } 
    this.userPagination(this.paginator); 
  }

  getVender() {
    this.Venderservice.getVenderList().subscribe(res => {
      if(res.success){
        this.venders = res.payload.venders
      }
    })
  }

  getUsers(){
    this.service.getUserList().subscribe(res => {
      this.pbVisible = true
      if(res.success){
        this.users = res.payload.users
        if(this.users.length !== 0){
          for(let user of this.users){
            let today = new Date(user.created_date)
            user.created_date = formatDate(today, 'longDate', 'en-US')
          }
          this.dataSource.data = this.users
          this.dataSource.filterPredicate = (data: UserList, filters: string) =>{
            const matchFilter = [];
            const filterArray = filters.split(' ')
            const columns = [data.name, data.mail_address]

            filterArray.forEach(filter =>{
              const customFilter = [];
              columns.forEach(column => customFilter.push(column.toLowerCase().includes(filter)));
              matchFilter.push(customFilter.some(Boolean));
            })
            return matchFilter.every(Boolean);
          }

        }
      }
    })
  }

  _applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilter(key : string){ 
    if(key){ 
      this.paginator.page = 1 ;
      this.paginator.key = key; 
      this.userPagination(this.paginator);
    } else { 
      this.paginator.page = 1 ;
      this.paginator.key = ''; 
      this.userPagination(this.paginator); 
    }
  }

  openDiaog(data: UserList){
    let venderName
    for(let x = 0; x < this.venders.length; x++){
      if(this.venders[x].id === data.vender){
        venderName = this.venders[x].name
      }
    }
    if (venderName){ data.vender = venderName }
    this.dialog.open(UserViewComponent, { width: '980px', height:'550px',
    data: data } )

  } 

  openOptionDialog(opt: string, val: UserList){
    const dialogRef = this.dialog.open(UserAddDialogComponent, { width: '980px', height:'550px',
      data: {
          option: opt, desc: val, venders: this.venders
        },
        disableClose: true
      })
      dialogRef.afterClosed().subscribe(res => {
        if(res) this.userPagination(this.paginator);
      })
  }

  openOptionDialogAdd(opt: string){
    let userTmp: UserList[] = []
    {
      const dialogRef = this.dialog.open(UserAddDialogComponent, { width: '980px', height:'550px',
      data: {
          option: opt, desc: userTmp , venders: this.venders
        },
        disableClose: true
      })
      dialogRef.afterClosed().subscribe(res => {
        if(res) this.userPagination(this.paginator);
      })
    }
  }

  onDelete(val: UserList){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {
        name: val.name
      }
    })
    dialogRef.afterClosed().subscribe(res => {
      if(res){
        let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
        let body = {
          'user_no': val.user_no,
          'deleted_by': tmp.userid 
        }
        this.service.userOpt(body, 'delete')
        .subscribe(res => {
          this.userPagination(this.paginator);
        })
      }
    })
  }
}
