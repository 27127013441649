import { Component, OnInit } from '@angular/core';
import { OrderService } from './shared/order.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  providers: [ OrderService ]
})
export class OrderComponent implements OnInit {

  constructor(public service: OrderService) { }

  ngOnInit() {
  }
}
