import { Injectable } from '@angular/core';
import { DataService } from '../../shared/data.service';
import { StoreSecondCatUrl, } from '../../shared/data.service';

@Injectable({
  providedIn: 'root'
})
export class StoreSecondCatService {

  constructor(private dataService: DataService) { }

  getSecondCategList(){
    return this.dataService.get(StoreSecondCatUrl, null);
  }
  saveCateg(body: any, opt :string){
    if(opt === 'delete')
      return this.dataService.storeSecondCatOpt(StoreSecondCatUrl + '/delete', body, opt)
    else
      return this.dataService.storeSecondCatOpt(StoreSecondCatUrl, body, opt)
  }
}
