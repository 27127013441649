import { Injectable } from '@angular/core';
import { DataService, systemUrl, importCSV } from '../../shared/data.service';

@Injectable({
  providedIn: 'root'
})
export class UploadTableCsvService {

  constructor(private dataService: DataService) { }

	uploadCSV(files: any, table_type:any, spreadsheet_key: any){
		const formData: FormData = new FormData();
		formData.append('csvFile', files);
		formData.append('table_type', table_type);
		formData.append('spreadSheetKey', spreadsheet_key);
		console.log(formData);
		return this.dataService.post(importCSV, formData);
	}
  // getTable(){
  //   return this.dataService.get(systemUrl + '/get-table-csv', null);
  // }
	//
  getFields(table: string){
    return this.dataService.get(systemUrl + '/fields/', table);
  }
	//
  // getData(body : any){
  //   return this.dataService.post(systemUrl, body);
  // }
	//
  // getRetailMasterData(body:any){
  //   return this.dataService.post(systemUrl +'/retail_master', body);
  // }

}
