import { Component, OnInit } from '@angular/core';
import { MaintenanceService } from './shared/maintenance.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  providers: [ MaintenanceService ]
})
export class MaintenanceComponent implements OnInit {

  constructor(public service: MaintenanceService) { }

  ngOnInit() {
  }
}
