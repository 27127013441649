import { StoreThirdCatService } from '../shared/store-third-cat.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators,FormControl } from '@angular/forms';

export interface KeyValue {
  key: string;
  value: string;
}

@Component({
  selector: 'app-store-third-cat-opt',
  templateUrl: './store-third-cat-opt.component.html',
  styleUrls: ['./store-third-cat-opt.component.scss']
})
export class StoreThirdCatOptComponent implements OnInit {

  categForm: FormGroup;
  opt: string
  disabledSubmit: boolean = false;
  utype: KeyValue[] = [
    { key: "av", value: "Disabled" },
    { key: "un", value: "Active" }
  ];
  utypeControl = new FormControl('', [Validators.required]);
  isLoad = false
  showLoading = false
  showErrorText = false
  errorText = ''

  constructor(public dialogRef: MatDialogRef<StoreThirdCatOptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private formBuilder: FormBuilder, private authService: StoreThirdCatService) { }

  ngOnInit() {  
    if(this.data.option === 'edit') this.opt = '変更'
    else this.opt = '新着追加'
    this.createForm();
    console.log(this.data.option)
  }

  createForm(): void {
    this.categForm = this.formBuilder.group({
      name: [ '', Validators.required ],
      note: [ '', Validators.required ]
    });
  }

  onSubmit(){
    this.isLoad = true
    this.showLoading = true
    let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
    const val: any = this.categForm.getRawValue()
    
    if ( this.categForm.invalid ) {
      this.showLoading = false
      this.showErrorText = true
      this.errorText = '重要なフィールドを記入してください'
      return
    }

    let body: any;
    if(this.data.option === 'add')
      body = { 'name': val.name,
        'note': val.note,
        'user_id': tmp.userid }
    else if(this.data.option === 'edit')
      body = { 'id': this.data.id,
        'name': val.name ,
        'note': val.note , 
        'user_id': tmp.userid }
    console.log(val,body)
    this.authService.saveCateg(body, this.data.option)
    .subscribe(res => {
      if(res){
        this.dialogRef.close(true)
      }else{
        this.showLoading = false
        this.showErrorText = true
        this.errorText = '接続の問題が再試行してください'
      }
    })
  }

  cancelSave(){
    this.isLoad = false
    this.showLoading = false
    this.showErrorText = false
  }

}
