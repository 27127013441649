import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carrier-first-cat-management',
  templateUrl: './carrier-first-cat-management.component.html',
  styleUrls: ['./carrier-first-cat-management.component.scss']
})
export class CarrierFirstCatManagementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
