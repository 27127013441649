import { Component, OnInit,ViewChild, OnDestroy,Input,HostListener } from '@angular/core';
import { Router, ActivatedRoute,Params } from '@angular/router';
import { Store } from '../../store/shared/store.model';
import { StoreService } from '../../store/shared/store.service';
import { Inquiry } from '../shared/inquiry.model';
import { InquiryService } from '../shared/inquiry.service';
import { InquiryData } from '../shared/inquiry.prov';
import { RelationalRefData } from '../../order/shared/order.prov';

@Component({
  selector: 'app-inquiry-complete',
  templateUrl: './inquiry-complete.component.html',
  styleUrls: ['./inquiry-complete.component.scss'],
  providers: [ StoreService,InquiryService]
})
export class InquiryCompleteComponent implements OnInit {

  id: string; //for store ID
  inquiryRefCode: string = "";
  hasData:boolean = false;  
  inquiry: Inquiry[] = [];
  store: Store[] = [];
  inqRefCode ='';

  esc_dir_name:string;
  isClearRefs = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private inquiryData: InquiryData,
    private rel: RelationalRefData,
  ) { }

  ngOnInit() {

    this.inqRefCode = this.inquiryData.ref_code;
    this.id = this.route.snapshot.paramMap.get('id');
    this.store = this.inquiryData.store;
    this.inquiry = this.inquiryData.inquiry;
    //this.inqRefCode = this.inquiryData.ref_code;

    if(this.inquiryData.store){
      this.hasData = true;
    }

    switch(this.inquiryData.inquiry.esc_direction){
      case 0:
      this.esc_dir_name ="コールセンター";
      break;
      case 1:
      this.esc_dir_name = "ラウンダー";
      break;

      case 2:
      this.esc_dir_name = "博報堂";
      break;

      case 3:
      this.esc_dir_name = "Google";
      break;

      case 4:
      this.esc_dir_name = "倉庫";
      break;

      default:
      this.esc_dir_name = "なし";
    }

  }

  ngOnDestroy(){
    if(this.isClearRefs){
      this.rel.inq_ref = ''
      this.rel.order_ref = ''
    }
  }

  toOrder(to) {
    this.isClearRefs = false
    this.router.navigate(['../../../../main/order/input/'+to]);
  }

  toCollection(to){
    this.isClearRefs = false
    this.router.navigate(['../../../../main/collection/input/'+to]);
  }

  toMaintenance(to){
    this.isClearRefs = false
    this.router.navigate(['../../../../main/maintenance/input/'+to]);
  }

}
